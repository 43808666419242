import { createAction, Middleware, PayloadAction } from "@reduxjs/toolkit";
import { authDeleteUser } from "../../firebase";
import { updateLocalSettings } from "../slices/localSettings.slice";
import { todosRemoved } from "../slices/todos.slice";
import { undoRedoReset } from "../slices/undoRedo.slice";
import { middleWareFunction, RootState } from "../store";
import { deleteUserSettings } from "./firebase/firebaseUserSettingsMiddleware";
import { userSettingsRemoved } from "../slices/userSettings.slice";
import { userRemoved } from "../slices/user.slice";

export const addUser = createAction("user/addUser");
export const deleteUser = createAction("user/deleteUser");

export const userMiddleware: Middleware<{}, RootState> =
    (api) => (next) => (action: PayloadAction) => {
        switch (action.type) {
            case deleteUser.type:
                _deleteUser(api, next, action);
                break;
        }

        return next(action);
    };

const _deleteUser: middleWareFunction = async (api, next, action) => {
    // const auth = getAuth();
    // const user = auth.currentUser;

    // await promptForCredentials().then((credential) => {
    //     reauthenticateWithCredential(user, credential).then(() => {
    //         // User re-authenticated.
    //     }).catch((error) => {
    //         // An error ocurred
    //         // ...
    //     });
    // }).catch((error) => {
    // });

    authDeleteUser();

    api.dispatch(
        updateLocalSettings({
            userLoaded: false,
            userSettingsLoaded: false,
            todosLoaded: false,
        })
    );

    api.dispatch(userRemoved(null));
    api.dispatch(userSettingsRemoved(null));
    api.dispatch(todosRemoved(null));
    api.dispatch(undoRedoReset(null));
};
