import { useState } from "react";
import SidenavButton from "../../sidenav/sidenav-button";
import Changelog from "./Tabs/Changelog";
import General from "./Tabs/General";
import Membership from "./Tabs/Membership";
import Profile from "./Tabs/Profile";

const Tabs = ["Profile", "Membership", "Changelog"];

export default function SettingsModal({ onClose }: { onClose?: () => void }) {
  const [tab, setTab] = useState<string>(Tabs[0]);

  return (
    <div className="w-full h-full rounded-xl">
      <section className="flex h-full">
        <nav className="h-full p-2 rounded-tl-xl rounded-bl-xl grow-0 w-52 bg-shark-light-100 dark:bg-shark-dark">
          <h3 className="block px-2 py-2 text-base font-medium ">Settings</h3>
          {Tabs.map((_tab, index) => (
            <div
              key={_tab}
              className={`my-0.5 ${tab === _tab ? "active" : ""}`}
            >
              <SidenavButton
                name={_tab.toString()}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => setTab(Tabs[index])}
              />
            </div>
          ))}
        </nav>
        <section className="w-full h-full overflow-auto grow">
          {/* {tab === "General" && <General />} */}
          {tab === "Profile" && <Profile />}
          {tab === "Membership" && <Membership />}
          {tab === "Changelog" && <Changelog />}
        </section>
      </section>
    </div>
  );
}
