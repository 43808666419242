import { useAppSelector } from "../../redux/hooks";
import TodoItem from "../../components/houi/TodoItem";
import { AnimatePresence, motion } from "framer-motion";
import DoneHeader from "./DoneHeader";
import { selectAllDoneTodos } from "../../redux/selectors/todosSelectors";

function DonePage() {
  const items = useAppSelector(selectAllDoneTodos);

  return (
    <>
      <DoneHeader />
      <ul>
        <AnimatePresence>
          {items!.map((item) => (
            <motion.li
              className="block my-1"
              key={item.id}
              animate={{ height: "auto", opacity: 1 }}
              exit={{
                height: 0,
                overflow: "hidden",
                opacity: 0,
              }}
              transition={{ ease: "easeOut", delay: 0.5, duration: 0.15 }}
            >
              <TodoItem id={item.id} flat />
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </>
  );
}

export default DonePage;
