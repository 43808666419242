import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { RaisedButton } from "../components/ui";
import { signInWithFacebook, signInWithGoogle } from "../firebase";
import useMousetrap from "../hooks/useMousetrap";

import { ReactComponent as CreateTodoIcon } from "./create-todo-icon.svg";

function Continue() {
  const [continueWithEmail, setContinueWithEmail] = useState(false);
  const [emailInputRef, setEmailInputRef] = useState<HTMLInputElement | null>();

  useMousetrap("escape", () => setContinueWithEmail(false));

  return (
    <motion.div
      className="flex h-screen prose"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        ease: "easeOut",
        duration: 0.6,
      }}
    >
      <div className="m-auto text-center w-96">
        <div className="flex w-20 h-20 p-4 mx-auto mb-8 bg-white border-2 shadow-xl border-shark-light-200 dark:border-shark-600 dark:bg-shark textColor rounded-xl">
          <CreateTodoIcon />
          {/* <h1 className="absolute top-3 left-1/2 -translate-x-1/2  !text-4xl !font-thin">
            I
          </h1>
          <h1 className="absolute top-2 left-1/2 -translate-x-1/2 !text-3xl !font-thin rotate-[90deg]">
            C
          </h1> */}
        </div>
        <h1>Log in/signup to CreateTodo</h1>
        <br />

        <AnimatePresence initial={false}>
          <motion.div
            className="overflow-hidden"
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: continueWithEmail === true ? 0 : "auto",
              opacity: continueWithEmail === true ? 0 : 1,
            }}
            transition={{
              ease: "easeOut",
              duration: 0.2,
            }}
          >
            <RaisedButton
              className="w-full !h-12 my-1"
              onClick={(e) => signInWithFacebook()}
            >
              Continue with Facebook
            </RaisedButton>

            <RaisedButton
              className="w-full !h-12 my-1"
              onClick={(e) => signInWithGoogle()}
            >
              Continue with Google
            </RaisedButton>
          </motion.div>
        </AnimatePresence>

        {/* <motion.div
          className="overflow-hidden"
          initial={{ height: 0, opacity: 0 }}
          animate={{
            height: continueWithEmail === true ? "auto" : 0,
            opacity: continueWithEmail === true ? 1 : 0,
          }}
          transition={{
            ease: "easeOut",
            duration: 0.2,
          }}
        >
          <input
            ref={setEmailInputRef}
            type="text"
            name="email"
            className="!h-12 my-1 text-input"
            placeholder="Enter your email"
          />
        </motion.div>
        <RaisedButton
          className="w-full !h-12 my-1"
          onClick={() => {
            setContinueWithEmail(true);
            if (continueWithEmail === false) {
              emailInputRef?.focus();
            }
          }}
        >
          Continue with Email
        </RaisedButton> */}
        <br />
        <br />
        <p className="text-xxs">
          By continuing, you agree to the Terms of Service and Private Policy.
        </p>
      </div>
    </motion.div>
  );
}

export default Continue;
