import { useRef, useEffect } from "react";

var mousetrap = require("mousetrap");

/**
 * Use mousetrap hook
 *
 * @param  {(string | string[])} handlerKey - A key, key combo or array of combos according to Mousetrap documentation.
 * @param  { Function } handlerCallback - A function that is triggered on key combo catch.
 * @param  { string } evtType - A string that specifies the type of event to listen for. It can be 'keypress', 'keydown' or 'keyup'.
 */
export default function useMousetrap(
  handlerKey: string | string[],
  handlerCallback: Function,
  disabled: boolean = false,
  evtType = "keydown"
) {
  let actionRef = useRef<Function>();
  actionRef.current = handlerCallback;

  function bind() {
    mousetrap.bind(
      handlerKey,
      (evt: any, combo: any) => {
        typeof actionRef.current === "function" &&
          actionRef.current(evt, combo);
      },
      evtType
    );
  }

  function unbind() {
    mousetrap.unbind(handlerKey);
  }

  useEffect(() => {
    bind();
    return () => {
      unbind();
    };
  }, [evtType, handlerKey]);

  useEffect(() => {
    disabled === true ? unbind() : bind();
    return () => {
      unbind();
    };
  }, [disabled]);
}
