import { createAction, Middleware, PayloadAction } from "@reduxjs/toolkit";
import {
    deleteField,
    doc,
    getDoc,
    setDoc,
    updateDoc
} from "firebase/firestore";
import { db } from "../../../firebase";
import { updateLocalSettings } from "../../slices/localSettings.slice";
import { setUserSettings, userSettingsUpdated } from "../../slices/userSettings.slice";
import { middleWareFunction, RootState } from "../../store";
import { UserSettings } from "../../types/userSettings";

const userSettings = "userSettings";

export const fetchUserSettings = createAction("userSettings/fetchUserSettings");
export const createUserSettings = createAction<UserSettings>("userSettings/createUserSettings");
export const deleteUserSettings = createAction("userSettings/deleteUserSettings");

export const firebaseUserSettingsMiddleware: Middleware<{}, RootState> =
    (api) => (next) => (action: PayloadAction) => {
        switch (action.type) {
            case fetchUserSettings.type:
                _fetchUserSettings(api, next, action);
                break;
            case createUserSettings.type:
                _createUserSettings(api, next, action);
                break;
            case userSettingsUpdated.type:
                _updateUserSettings(api, next, action);
                break;
        }

        return next(action);
    };

const _fetchUserSettings: middleWareFunction = async (api, next, action) => {
    try {
        const docRef = doc(db, userSettings, api.getState().user.id)

        const userSettingsDocSnapshot = await getDoc(docRef);

        if (userSettingsDocSnapshot.exists()) {
            api.dispatch(setUserSettings(userSettingsDocSnapshot.data()));
            api.dispatch(updateLocalSettings({ userSettingsLoaded: true }));
        } else {
            // TODO: Listen to when the document is added instead of adding one.
            console.log("UserSettings not found, creating default settings");
            api.dispatch(createUserSettings({
                uid: api.getState().user.id,
                fullWidth: true,
                centerTodos: true,
                leftSidebar: true,
                showCreatedDateOfTodos: false,
                createTodoButton: true,
                pageSettings: [
                    {
                        id: "todos",
                        settings: {
                            showDoneTodos: false,
                        },
                    },
                ],
            } as UserSettings))
        }
    } catch (e) { console.log(e) }
};

const _createUserSettings: middleWareFunction = async (api, next, action) => {
    try {
        await setDoc(doc(db, userSettings, api.getState().user.id), JSON.parse(JSON.stringify(action.payload)));
        api.dispatch(setUserSettings(JSON.parse(JSON.stringify(action.payload))));
        api.dispatch(updateLocalSettings({ userSettingsLoaded: true }));
    } catch (e) { }
};

const _updateUserSettings: middleWareFunction = async (api, next, action) => {
    try {
        const change = action.payload as Partial<UserSettings>;
        const prevChange = Object.entries(change).reduce<Partial<UserSettings>>(
            (acc, cur) => ({ ...acc, [cur[0]]: cur[1] ?? deleteField() }),
            {}
        );
        await updateDoc(doc(db, userSettings, api.getState().user.id), prevChange);
    } catch (e) { console.log(e) }
};