import {
  forwardRef,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import "./index.scss";
import useCombinedRefs from "../../../hooks/use_combined_refs";

/**
 * @deprecated
 */
interface MCButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  filled?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  wrapperClassname?: string;
  stopPropagation?: boolean;
  disableTextColor?: boolean;
}

const MCButton = forwardRef<HTMLButtonElement, MCButtonProps>(
  (
    {
      title,
      filled,
      outlined,
      disabled,
      className,
      wrapperClassname,
      children,
      onClick,
      stopPropagation,
      disableTextColor,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLButtonElement>(null);
    const combinedRef = useCombinedRefs(innerRef, ref);
    const [stopPropagationFunctions, setPtopPropagationFunctions] =
      useState<any>();

    useEffect(() => {
      if (stopPropagation === true) {
        setPtopPropagationFunctions({
          onKeyDown: (e: any) => e.stopPropagation(),
          onPointerDown: (e: any) => e.stopPropagation(),
          onMouseDown: (e: any) => e.stopPropagation(),
        });
      } else {
        setPtopPropagationFunctions(null);
      }
    }, [stopPropagation]);

    return (
      <div
        className={`mdc-touch-target-wrapper ${
          wrapperClassname ? wrapperClassname : ""
        }`}
        {...stopPropagationFunctions}
        onMouseDown={(e) => e.preventDefault()}
        onClick={onClick as MouseEventHandler<HTMLButtonElement> | undefined}
      >
        <button
          ref={combinedRef}
          className={`mdc-button min-w-0 mdc-button--touch h-7 rounded m-0
            ${
              filled
                ? "mdc-button--unelevated bg-shark-light-100 dark:bg-shark-500 border border-solid  shadow-sm ripple-filled"
                : "ripple"
            }
            ${
              outlined
                ? "ring-1 ring-shark-light-400 dark:ring-shark-400 hover:ring-blue focus:ring-blue transition"
                : ""
            }
            ${
              disabled
                ? "border-b-shark-500/25 dark:border-shark-500"
                : "border-t-shark-200/25 border-x-shark-200/25 border-b-shark-400/25 dark:border-t-shark-300 dark:border-x-shark-300 dark:border-b-shark-400"
            }
            ${className}
          `}
          disabled={disabled}
          {...props}
        >
          {/* <span className="mdc-button__ripple"></span> */}
          {/* <span className="mdc-button__touch"></span> */}
          <span className="mdc-button__focus-ring"></span>
          <span
            className={`text-xs normal-case font-medium  whitespace-nowrap text-xxs  transition-color
            ${filled ? "" : ""} 
            ${disabled ? "text-opacity-25 dark:text-opacity-50" : ""} 
            ${
              disableTextColor
                ? "text-current"
                : "text-cadet-blue-800 dark:text-cadet-blue-dark-text-color"
            }`}
          >
            {children}
          </span>
        </button>
      </div>
    );
  }
);

export default MCButton;
