import {
  createEntityAdapter,
  createSlice,
  EntityState,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Todo } from "../types";

export const todosAdapter = createEntityAdapter<Todo>({
  sortComparer: (a, b) => {
    return a.sortOrder !== undefined && b.sortOrder !== undefined
      ? a.sortOrder - b.sortOrder
      : a.sortOrder !== undefined
        ? -1
        : b.sortOrder !== undefined
          ? 1
          : a.createdAt - b.createdAt;
  },
});

const todosSlice = createSlice<
  EntityState<Todo>,
  SliceCaseReducers<EntityState<Todo>>
>({
  name: "todos",
  initialState: todosAdapter.getInitialState,
  reducers: {
    todosLoaded: todosAdapter.addMany,
    todoAdded: todosAdapter.addOne,
    todosAdded: todosAdapter.addMany,
    todoUpdated: todosAdapter.updateOne,
    todosUpdated: todosAdapter.updateMany,
    todoDeleted: todosAdapter.removeOne,
    todosDeleted: todosAdapter.removeMany,
    todosRemoved: todosAdapter.removeAll,
  },
});

export const {
  todosLoaded,
  todoAdded,
  todosAdded,
  todoUpdated,
  todosUpdated,
  todoDeleted,
  todosDeleted,
  todosRemoved,
} = todosSlice.actions;

export default todosSlice.reducer;

export const todosSelectors = todosAdapter.getSelectors<RootState>(
  (state) => state.todos
);
