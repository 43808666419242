import { mdiPlus } from "@mdi/js";
import { useState } from "react";
import useMousetrap from "../../hooks/useMousetrap";
import { useAppSelector } from "../../redux/hooks";
import { selectDisableGlobalShortcuts } from "../../redux/slices/localSettings.slice";
import Modal from "../loui/Modal";
import Tooltip from "../loui/Tooltip";
import CreateTodoModal from "./CreateTodoModal";
import SidenavIconButton from "./sidenav/sidenav-icon-button";

function CreateTodoButton() {
  const disableGlobalShortcuts = useAppSelector(selectDisableGlobalShortcuts);
  const [open, setOpen] = useState(false);

  useMousetrap("c", () => {
    if (!disableGlobalShortcuts) setOpen(true);
  });

  return (
    <Modal Content={CreateTodoModal} open={open} setOpen={setOpen}>
      <Tooltip description="Create new todo" shortcut="C" placement="right">
        <SidenavIconButton icon={mdiPlus} />
      </Tooltip>
    </Modal>
  );
}

export default CreateTodoButton;
