import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "../types";

const userSlice = createSlice<User, SliceCaseReducers<User>>({
  name: "user",
  initialState: null,
  reducers: {
    userAdded: (state, action: PayloadAction<User>) => action.payload,
    userUpdated: (state, action: PayloadAction<Partial<User>>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    userRemoved: (state, action) => null,
  },
});

export const { userAdded, userUpdated, userRemoved } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.user;
