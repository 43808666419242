import { AnimatePresence, motion } from "framer-motion";
import CreateTodo from "../components/create_todo";
import TodoItem from "../components/houi/TodoItem";
import { useAppSelector } from "../redux/hooks";
import { selectTodosByStatus } from "../redux/selectors/todosSelectors";
import { TodoState } from "../redux/types/todo";
import PageHeader from "./components/PageHeader";

export default function BacklogPage() {
  const items = useAppSelector((state) =>
    selectTodosByStatus(state, TodoState.backlog)
  );

  return (
    <>
      <PageHeader title="Backlog" todoCount={items.length} />
      <ul>
        <AnimatePresence>
          {items!.map((item) => (
            <motion.li
              className="block my-1"
              key={item.id}
              animate={{ height: "auto", opacity: 1 }}
              exit={{
                height: 0,
                overflow: "hidden",
                opacity: 0,
              }}
              transition={{ ease: "easeOut", delay: 0.5, duration: 0.15 }}
            >
              <TodoItem id={item.id} flat />
            </motion.li>
          ))}
          <li>
            <CreateTodo
              placeholder="Create backlog todo"
              status={TodoState.backlog}
            />
          </li>
        </AnimatePresence>
      </ul>
    </>
  );
}

// function Header() {
//   // const menuContent = (setOpen: any) => (
//   //   <>
//   //     <section className="m-4 prose w-52">
//   //       <h1>Backlog</h1>
//   //       <p>Backlog is where ideas to todos can be stored.</p>
//   //       {/* <RaisedButton>Got it</RaisedButton> */}
//   //     </section>
//   //   </>
//   // );

//   return (
//     <PageHeader
//       title="Backlog"
//       // menuContent={menuContent}
//       // rightContent={
//       //   <>
//       //     <RaisedButton className="border-r-0 rounded-r-none" mini disabled>
//       //       <div className="w-3 h-34">
//       //         <Icon path={mdiViewSequential} />
//       //       </div>
//       //     </RaisedButton>
//       //     <RaisedButton className="border-l-0 rounded-l-none" mini>
//       //       <div className="w-3 h-34">
//       //         <Icon path={mdiViewWeek} />
//       //       </div>
//       //     </RaisedButton>
//       //   </>
//       // }
//     />
//   );
// }
