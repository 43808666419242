import "./index.scss";

import {
  mdiCheck,
  mdiCircleSmall,
  mdiLightningBoltOutline,
  mdiSquareRoundedOutline,
} from "@mdi/js";
import { NavLink, Outlet } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import {
  selectCenterTodos,
  selectFullWidth,
  selectLeftSidebar,
} from "../../redux/slices/userSettings.slice";
import CreateTodoButton from "./create_todo_button";
import SidenavButton from "./sidenav/sidenav-button";
import SidenavTitle from "./sidenav/SidenavTitle";
import UserButton from "./UserButton";

export default function AppLayout() {
  const fullWidth = useAppSelector(selectFullWidth);
  const centerTodos = useAppSelector(selectCenterTodos);
  const leftSidebar = useAppSelector(selectLeftSidebar);

  return (
    <div className={`relative flex h-full ${leftSidebar ? "pl-56" : ""}`}>
      <nav
        className={`fixed inset-0 flex flex-col bg-shark-light-100 dark:bg-shark-dark border-shark-light-200 dark:border-shark-600 border-r w-56 p-4 ${
          leftSidebar ? "" : "-translate-x-56"
        }`}
      >
        <div className="flex">
          <div className="grow">
            <UserButton />
          </div>
          <div className="flex items-center grow-0 h-7">
            <CreateTodoButton />
          </div>
        </div>
        <div className="block grow">
          <ul className="my-6">
            <li className="py-px">
              <SidenavTitle title="Status" />
            </li>
            <li className="py-px">
              <NavLink to="/backlog" tabIndex={-1}>
                <SidenavButton icon={mdiCircleSmall} name="Backlog" />
              </NavLink>
            </li>
            <li className="py-px">
              <NavLink to="/todo" tabIndex={-1}>
                <SidenavButton icon={mdiSquareRoundedOutline} name="Todo" />
              </NavLink>
            </li>
            <li className="py-px">
              <NavLink to="/doing" tabIndex={-1}>
                <SidenavButton icon={mdiLightningBoltOutline} name="Doing" />
              </NavLink>
            </li>
            <li className="py-px">
              <NavLink to="/done" tabIndex={-1}>
                <SidenavButton icon={mdiCheck} name="Done" />
              </NavLink>
            </li>
            {/* <li className="py-px">
              <NavLink to="/board" tabIndex={-1}>
                <SidenavButton icon={mdiLayersTriple} name="Board" />
              </NavLink>
            </li> */}
          </ul>

          {/* <section className="my-6">
            <h2 className="m-2 font-medium uppercase text-xxs disabledTextColor">
              Calendar
            </h2>
            <SidenavButton disabled icon={mdiWeatherSunny} name="Today" />
            <SidenavButton
              disabled
              icon={mdiViewWeekOutline}
              name="This week"
            />
            <SidenavButton
              disabled
              icon={mdiCalendarMonthOutline}
              name="This month"
            />
          </section> */}

          {/* <section className="my-6">
            <h2 className="m-2 font-medium uppercase text-xxs disabledTextColor">
              Lists
            </h2>
            <NavLink to="/1" tabIndex={-1}>
              <SidenavButton name="Createtodo" />
            </NavLink>
            <NavLink to="/2" tabIndex={-1}>
              <SidenavButton name="Habitory" />
            </NavLink>
            <NavLink to="/3" tabIndex={-1}>
              <SidenavButton name="Private" />
            </NavLink>
          </section> */}
        </div>
      </nav>

      <main
        className={`w-full p-2  print:max-w-none print:px-0 print:py-4 transition-all max-w-full
        ${!fullWidth ? "max-w-xl" : ""}
        ${centerTodos ? "mx-auto" : ""}
        `}
      >
        <div className="pb-16 mx-10 mt-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
