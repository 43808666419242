import { Dispatch, Middleware, MiddlewareAPI, Update } from "@reduxjs/toolkit";
import { selectTodo, selectTodos } from "../selectors/todosSelectors";
import {
  todoAdded,
  todoDeleted,
  todosAdded,
  todosDeleted,
  todosUpdated,
  todoUpdated,
} from "../slices/todos.slice";
import { addUndoAction, redo, undo } from "../slices/undoRedo.slice";
import { middleWareFunction, RootState } from "../store";
import { Todo } from "../types";
import { UndoAction } from "../types/undoRedo";

export type middleWareUndoFunction = (
  api: MiddlewareAPI<Dispatch<UndoAction>, RootState>,
  next: Dispatch<UndoAction>,
  action: UndoAction
) => void;

export const undoRedoMiddleware: Middleware<{}, RootState> =
  (api) => (next) => (action: UndoAction) => {
    if (
      action.type !== undo.type &&
      action.type !== redo.type &&
      action.isUndoAction === true
    ) {
      delete action.undoAction;
      delete action.isUndoAction;
      return next(action);
    }

    switch (action.type) {
      case undo.type:
        _undo(api, next, action);
        break;
      case redo.type:
        _redo(api, next, action);
        break;
      case todoAdded.type:
        _todoAdded(api, next, action);
        break;
      case todoUpdated.type:
        _todoUpdated(api, next, action);
        break;
      case todosUpdated.type:
        _todosUpdated(api, next, action);
        break;
      case todoDeleted.type:
        _todoDeleted(api, next, action);
        break;
      case todosDeleted.type:
        _todosDeleted(api, next, action);
        break;
    }

    return next(action);
  };

const _undo: middleWareFunction = (api, next, action) => {
  const undoRedo = api.getState().undoRedo;
  if (undoRedo.index > -1) {
    api.dispatch({
      ...undoRedo.actions[undoRedo.index].undoAction,
      isUndoAction: true,
    } as UndoAction);
  }
};

const _redo: middleWareFunction = (api, next, action) => {
  const undoRedo = api.getState().undoRedo;
  if (undoRedo.index < undoRedo.actions.length - 1) {
    api.dispatch({
      ...undoRedo.actions[undoRedo.index + 1],
      isUndoAction: true,
    } as UndoAction);
  }
};

const _todoAdded: middleWareFunction = (api, next, action: UndoAction) => {
  const payload = action.payload as unknown as { id: string; name: string };

  api.dispatch(
    addUndoAction({
      ...action,
      undoAction: todoDeleted(payload.id),
    })
  );
};

const _todoUpdated: middleWareFunction = (api, next, action: UndoAction) => {
  const todo = selectTodo(api.getState(), action.payload.id);

  if (todo) {
    const change = action.payload.changes as Partial<Todo>;

    const prevChange = Object.entries(change).reduce<Partial<Todo>>(
      (acc, cur) => {
        const prev = Object.entries(todo).find((val) => val[0] === cur[0]);
        return prev
          ? { ...acc, [prev[0]]: prev[1] }
          : { ...acc, [cur[0]]: undefined };
      },
      {}
    );

    todo
      ? api.dispatch(
        addUndoAction({
          ...action,
          undoAction: todoUpdated({
            id: action.payload.id,
            changes: prevChange,
          }),
        })
      )
      : api.dispatch(undo(null));
  }
};

const _todosUpdated: middleWareFunction = (api, next, action: UndoAction) => {
  const todos = selectTodos(
    api.getState(),
    action.payload.map((changes: Update<Todo>) => changes.id)
  );

  if (todos.length > 0) {
    const changes = (action.payload as Update<Todo>[]).map(
      (changes) => changes.changes
    ) as Partial<Todo>[];

    const prevChanges = changes.reduce<Partial<Todo>[]>(
      (acc1, cur1, index1) => {
        const prevChange = Object.entries(changes[1]).reduce<Partial<Todo>>(
          (acc2, cur2) => {
            const prev = Object.entries(todos[index1]).find(
              (val) => val[0] === cur2[0]
            );
            return prev ? { ...acc2, [prev[0]]: prev[1] } : acc2;
          },
          {}
        );
        return [
          ...acc1,
          {
            id: todos[index1].id,
            changes: prevChange,
          },
        ];
      },
      []
    );

    prevChanges.length > 0
      ? api.dispatch(
        addUndoAction({
          ...action,
          undoAction: todosUpdated(prevChanges),
        })
      )
      : api.dispatch(undo(null));
  }
};

const _todoDeleted: middleWareFunction = (api, next, action: UndoAction) => {
  const todo = selectTodo(api.getState(), action.payload);

  if (todo) {
    api.dispatch(
      addUndoAction({
        ...action,
        undoAction: todoAdded(todo),
      })
    );
  }
};

const _todosDeleted: middleWareFunction = (api, next, action: UndoAction) => {
  const todosToBeDeleted = selectTodos(api.getState(), action.payload);

  if (todosToBeDeleted.length > 0) {
    api.dispatch(
      addUndoAction({
        ...action,
        undoAction: todosAdded(todosToBeDeleted),
      })
    );
  }
};
