import { createAction, Middleware } from "@reduxjs/toolkit";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import {
  todoAdded,
  todoDeleted,
  todosAdded,
  todosDeleted,
  todosLoaded,
  todosUpdated,
  todoUpdated,
} from "../../slices/todos.slice";
import { middleWareFunction, RootState } from "../../store";
import { Todo } from "../../types";
import { UndoAction } from "../../types/undoRedo";
import { updateLocalSettings } from "../../slices/localSettings.slice";

const todos = "todos";

export const fetchTodos = createAction("todos/fetchTodos");

export const firebaseTodosMiddleware: Middleware<{}, RootState> =
  (api) => (next) => (action: UndoAction) => {
    switch (action.type) {
      case fetchTodos.type:
        _fetchTodos(api, next, action);
        break;
      case todoAdded.type:
        _todoAdded(api, next, action);
        break;
      case todosAdded.type:
        _todosAdded(api, next, action);
        break;
      case todoUpdated.type:
        _todoUpdated(api, next, action);
        break;
      case todosUpdated.type:
        _todosUpdated(api, next, action);
        break;
      case todoDeleted.type:
        _todoDeleted(api, next, action);
        break;
      case todosDeleted.type:
        _todosDeleted(api, next, action);
        break;
    }

    return next(action);
  };

const _fetchTodos: middleWareFunction = async (api, next, action) => {
  try {
    const todosQuery = query(collection(db, todos), where("creatorId", "==", api.getState().user.id));

    const todosQuerySnapshot = await getDocs(todosQuery);

    api.dispatch(todosLoaded(todosQuerySnapshot.docs.map((doc) => doc.data())));
    api.dispatch(updateLocalSettings({ todosLoaded: true }));
  } catch (e) { }
};

const _todoAdded: middleWareFunction = async (api, next, action) => {
  const todo = action.payload;
  try {
    await setDoc(doc(db, todos, todo.id), JSON.parse(JSON.stringify(todo)));
  } catch (e) { }
};

const _todosAdded: middleWareFunction = async (api, next, action) => {
  action.payload.forEach(async (todo: Todo) => {
    try {
      await setDoc(doc(db, todos, todo.id), JSON.parse(JSON.stringify(todo)));
    } catch (e) { }
  });
};

const _todoUpdated: middleWareFunction = async (api, next, action) => {
  try {
    const id = action.payload.id;
    const change = action.payload.changes as Partial<Todo>;
    const prevChange = Object.entries(change).reduce<Partial<Todo>>(
      (acc, cur) => ({ ...acc, [cur[0]]: cur[1] ?? deleteField() }),
      {}
    );
    await updateDoc(doc(db, todos, id), prevChange);
  } catch (e) { }
};

const _todosUpdated: middleWareFunction = async (api, next, action) => {
  action.payload.forEach(async (payload: any) => {
    try {
      const id = payload.id;
      const change = payload.changes as Partial<Todo>;
      const prevChange = Object.entries(change).reduce<Partial<Todo>>(
        (acc, cur) => ({ ...acc, [cur[0]]: cur[1] ?? deleteField() }),
        {}
      );
      await updateDoc(doc(db, todos, id), prevChange);
    } catch (e) { }
  });
};

const _todoDeleted: middleWareFunction = async (api, next, action) => {
  try {
    await deleteDoc(doc(db, todos, action.payload));
  } catch (e) { }
};

const _todosDeleted: middleWareFunction = async (api, next, action) => {
  action.payload.forEach(async (id: string) => {
    try {
      await deleteDoc(doc(db, todos, id));
    } catch (e) { }
  });
};