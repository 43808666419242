import { Middleware, PayloadAction, createAction } from "@reduxjs/toolkit";
import { deleteUser, getAuth } from "firebase/auth";
import { deleteDoc, deleteField, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { updateLocalSettings } from "../../slices/localSettings.slice";
import { userAdded, userRemoved, userUpdated } from "../../slices/user.slice";
import { RootState } from "../../store";
import { User } from "../../types";
import { fetchTodos } from "./firebaseTodosMiddleware";
import { fetchUserSettings } from "./firebaseUserSettingsMiddleware";


const userDBPath = "users";

export const fetchUser = createAction<User>("user/fetchUser");
export const createUser = createAction<User>("user/createUser");

export const firebaseUserMiddleware: Middleware<{}, RootState> = (api) => (next) => (action: PayloadAction) => {
    switch (action.type) {
        case fetchUser.type:
            _fetchUser(api, next, action as unknown as PayloadAction<User>);
            break;
        case createUser.type:
            _createUser(api, next, action as unknown as PayloadAction<User>);
            break;
        case userUpdated.type:
            _updateUser(api, next, action);
            break;
        case "user/deleteUser":
            _deleteUser(api, next, action);
            break;
    }

    return next(action);
};

const _fetchUser = async (api: any, next: any, action: PayloadAction<User>) => {
    try {
        const user = action.payload;

        const docRef = doc(db, userDBPath, user.id);

        const userDocSnapshot = await getDoc(docRef);

        if (userDocSnapshot.exists()) {
            api.dispatch(userAdded(user));

            api.dispatch(updateLocalSettings({ userLoaded: true }));

            api.dispatch(fetchUserSettings());
            api.dispatch(fetchTodos());

            //TODO: Update property updatedAt
        } else {
            api.dispatch(createUser({
                id: user.id,
                active: true,
                email: user.email,
                name: user.displayName,
                displayName: user.displayName,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                createdAt: new Date().valueOf(),
                updatedAt: new Date().valueOf(),
            }));

        }

    } catch (error) { console.log(error) }
};

/**  */
const _createUser = async (api: any, next: any, action: PayloadAction<User>) => {
    const user = await getDoc(doc(db, userDBPath, action.payload.id));

    if (user.exists()) {
        api.dispatch(userAdded(user.data() as User));
    } else {
        // TODO: Listen to when the document is added instead of adding one.
        await setDoc(doc(db, userDBPath, action.payload.id), JSON.parse(JSON.stringify(action.payload)));
        api.dispatch(userAdded(action.payload));
    }


    api.dispatch(fetchUserSettings());
    api.dispatch(fetchTodos());

    api.dispatch(updateLocalSettings({ userLoaded: true }));
};

const _updateUser = async (api: any, next: any, action: any) => {
    try {
        const change = action.payload as Partial<User>;
        const prevChange = Object.entries(change).reduce<Partial<User>>(
            (acc, cur) => ({ ...acc, [cur[0]]: cur[1] ?? deleteField() }),
            {}
        );
        await updateDoc(doc(db, userDBPath, api.getState().user.id), prevChange);
    } catch (e) { console.log(e) }
};

/** Records associated with the user is deleted by Firebase Functions. */
const _deleteUser = async (api: any, next: any, action: any) => {
    api.dispatch(userRemoved(null));

    const auth = getAuth();
    const user = auth.currentUser;

    deleteUser(user).then(() => {
        // User deleted.
        console.log("User deleted");
    }).catch((error) => {
        console.log(error);
        // An error ocurred
        // ...
    });
};