import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PageSettings, UserSettings } from "../types/userSettings";

const userSettingsSlice = createSlice<
    UserSettings | null,
    SliceCaseReducers<UserSettings | null>
>({
    name: "userSettings",
    initialState: null,
    reducers: {
        setUserSettings: (state, action: PayloadAction<UserSettings>) => {
            return action.payload;
        },
        userSettingsRemoved: () => {
            return null;
        },
        userSettingsUpdated: (state, action: PayloadAction<Partial<UserSettings>>) => {
            return {
                ...state,
                ...action.payload
            }
        },
        // TODO: Uppdate specific pagesettings and not all...
        setPageSettings: (state, action: PayloadAction<PageSettings<any>>) => {
            state!.pageSettings = [
                ...(state!.pageSettings?.filter(
                    (obj) => obj.id !== action.payload.id
                ) ?? []),
                { ...action.payload },
            ];
        },
    },
});

export const {
    setUserSettings,
    userSettingsRemoved,
    userSettingsUpdated,
    setPageSettings,
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;

export const selectUserSettings = (state: RootState) => state.userSettings;
export const selectFullWidth = (state: RootState) =>
    state.userSettings!.fullWidth;
export const selectCenterTodos = (state: RootState) =>
    state.userSettings!.centerTodos;
export const selectLeftSidebar = (state: RootState) =>
    state.userSettings!.leftSidebar;
export const selectToggleCreateTodoButton = (state: RootState) =>
    state.userSettings!.createTodoButton;
export const selectShowCreatedDateOfTodos = (state: RootState) =>
    state.userSettings!.showCreatedDateOfTodos;

export const selectPageSettings = (state: RootState, pageId: string) =>
    state.userSettings!.pageSettings?.find(
        (pageSetting) => pageSetting.id === pageId
    );