import { Placement } from "@popperjs/core/lib/enums";
import { OffsetsFunction } from "@popperjs/core/lib/modifiers/offset";
import { VirtualElement } from "@popperjs/core/lib/popper-lite";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { usePopper } from "react-popper";
import { useImmer } from "use-immer";

declare type Offset =
  | OffsetsFunction
  | [number | null | undefined, number | null | undefined];

interface Props {
  /** If the first element in the menu should automatically be focused when menu opens. */
  autoFocus?: boolean;

  children: any;

  /** The content that should be displayed in the menu. */
  content: any;

  /** Whether opens as a normal menu with a button or similar, or by right clicking the element. */
  contextMenu?: boolean;

  /** Preferent place of placement. */
  placement?: Placement;

  /**
   * Offset of the menu.
   * @default [0, 4]
   */
  offset?: Offset;

  /** Min width of the menu inseted into the tailwind class min-w-[**], default to **"13rem"** (208px). */
  minWidth?: string;

  /** Sets the display property of the div wrapper. Defaults to inline-block. */
  wrapperDisplay?: "inline-block" | "block" | "inline-flex";

  autoOpen?: boolean;

  open?: boolean;

  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Menu({
  minWidth = "13rem",
  wrapperDisplay = "inline-block",
  autoFocus = false,
  ...props
}: Props) {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.open !== undefined && props.open !== open) {
      setOpen(props.open);
    }
  }, [props.open]);

  function generateGetBoundingClientRect(x = 0, y = 0): () => DOMRect {
    return () =>
      ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x,
        x: x,
        y: y,
      } as DOMRect);
  }

  const [virtualElement, setVirtualElement] = useImmer<VirtualElement>({
    getBoundingClientRect: generateGetBoundingClientRect(),
  });

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popperInstance = usePopper(
    props.contextMenu && props.placement === undefined
      ? virtualElement
      : referenceElement,
    popperElement,
    {
      placement: props.placement ?? "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: props.offset ? props.offset : [0, 4],
          },
        },
      ],
    }
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (event.key === "Escape") {
        console.log("menu");
        event.stopPropagation();
        event.preventDefault();
        setOpen(false);
        props.setOpen?.(false);
      }
    },
    [props]
  );

  useEffect(() => {
    open
      ? document.addEventListener("keydown", handleKeyDown, { passive: true })
      : document.removeEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, open]);

  return (
    <>
      <div
        className={`${wrapperDisplay} ${open ? "open" : "closed"}`}
        ref={setReferenceElement}
        onClick={
          props.contextMenu
            ? undefined
            : () => {
                setOpen((s) => !s);
                props.setOpen?.((s) => !s);
              }
        }
        onContextMenu={(e) => {
          if (props.contextMenu) {
            setVirtualElement((s) => {
              s.getBoundingClientRect = generateGetBoundingClientRect(
                e.clientX,
                e.clientY
              );
            });
            popperInstance.update?.();
            setOpen((s) => !s);
            props.setOpen?.((s) => !s);
            e.preventDefault();
            // e.stopPropagation();
          }
        }}
      >
        {props.children}
      </div>
      {createPortal(
        <AnimatePresence>
          {open && (
            <FocusLock autoFocus={autoFocus}>
              <div
                className="fixed inset-0 z-50"
                onClick={() => {
                  setOpen((s) => !s);
                  if (props.setOpen) {
                    props.setOpen((s) => !s);
                  }
                }}
              >
                <div
                  ref={setPopperElement}
                  style={popperInstance.styles.popper}
                  {...popperInstance.attributes.popper}
                >
                  <motion.div
                    initial={{
                      opacity: 1,
                      scale: 1,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                    }}
                    exit={{
                      opacity: 0,
                      scale: 0.9,
                    }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.15,
                    }}
                    className={`py-1 inline-block text-xs min-w-[${minWidth}] menu`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {props.content(props.setOpen ?? setOpen)}
                  </motion.div>
                </div>
              </div>
            </FocusLock>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
}

export default Menu;
