import { useMemo } from "react";
import Menu from "../../components/loui/Menu";
import { FlatButton, FlatButtonWithArrow } from "../../components/ui";

interface Props {
  title: string;
  menuContent?: any;
  rightContent?: any;
  todoCount?: number;
}

function PageHeader(props: Props) {
  const title = useMemo(
    () => (
      <h1>
        {props.title}
        <span
          className={`ml-2 font-mono disabledTextColor ${
            !props.todoCount ? "hidden" : ""
          }`}
        >
          {props.todoCount}
        </span>
      </h1>
    ),
    [props.title, props.todoCount]
  );

  return (
    <section className="flex items-center pb-2 mb-2 border-b-2 print:hidden border-shark-light-200 dark:border-shark-600">
      <div className="grow">
        {props.menuContent ? (
          <Menu content={props.menuContent} autoFocus={false}>
            <FlatButtonWithArrow className="prose">{title}</FlatButtonWithArrow>
          </Menu>
        ) : (
          <FlatButton className="prose" disabled>
            {title}
          </FlatButton>
        )}
      </div>
      {props.rightContent && <div className="grow-0">{props.rightContent}</div>}
    </section>
  );
}

export default PageHeader;
