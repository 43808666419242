import FocusLock from "react-focus-lock";
import { AnimatePresence, motion } from "framer-motion";
import { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import usePrefersColorScheme from "../../../hooks/usePrefersColorScheme";
import { useAppDispatch } from "../../../redux/hooks";
import { setDisableGlobalShortcuts } from "../../../redux/slices/localSettings.slice";

interface Props {
  Content: any;
  children?: any;
  disableClickedOutside?: boolean;
  open?: boolean;
  width?: number;
  height?: number;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Modal(props: Props) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "escape") {
      setOpen(false);
      props.setOpen?.(false);
    }
  };

  useEffect(() => {
    open
      ? window.addEventListener("keydown", handleKeyDown, { passive: true })
      : window.removeEventListener("keydown", handleKeyDown);

    dispatch(setDisableGlobalShortcuts(open));

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  const prefersColorScheme = usePrefersColorScheme();
  const isDarkMode = prefersColorScheme === "dark";

  useEffect(() => {
    if (props.open !== undefined && props.open !== open) {
      setOpen(props.open);
    }
  }, [props.open]);

  const inBackdropCss = {
    background: "rgb(22 24 29 / " + (isDarkMode ? "0.5)" : "0.1)"),
  };

  return (
    <>
      <div
        onClick={() => {
          setOpen((s) => !s);
          props.setOpen?.((s) => !s);
        }}
      >
        {props.children}
      </div>
      {createPortal(
        <>
          <AnimatePresence>
            {open && (
              <FocusLock autoFocus={false}>
                <motion.div
                  className="fixed inset-0 z-10"
                  initial={inBackdropCss}
                  animate={inBackdropCss}
                  exit={{
                    background: "rgb(22 24 29 / 0)",
                  }}
                  transition={{
                    ease: "easeInOut",
                    duration: 0.15,
                  }}
                  onClick={() => {
                    if (!props.disableClickedOutside) {
                      setOpen(false);
                      props.setOpen?.(false);
                    }
                  }}
                >
                  <div className="relative flex items-start justify-center h-full text-center">
                    <motion.div
                      onClick={(e) => e.stopPropagation()}
                      initial={{
                        opacity: 1,
                        scale: 1,
                        top: 0,
                      }}
                      animate={{
                        opacity: 1,
                        scale: 1,
                        top: 0,
                      }}
                      exit={{
                        opacity: 0,
                        scale: 0.9,
                        top: 20,
                      }}
                      transition={{
                        ease: "easeInOut",
                        duration: 0.15,
                      }}
                      className="self-center max-h-full text-xs text-left rounded-xl surface"
                      style={{
                        height: props.height,
                        width: props.width,
                      }}
                    >
                      <props.Content
                        onClose={() => {
                          setOpen(false);
                          props.setOpen?.(false);
                        }}
                      />
                    </motion.div>
                  </div>
                </motion.div>
              </FocusLock>
            )}
          </AnimatePresence>
        </>,
        document.body
      )}
    </>
  );
}

export default memo(Modal);
