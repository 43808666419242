import { Placement } from "@popperjs/core/lib/enums";
import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addTooltipOpen,
  removeTooltipOpen,
  selectHasTooltipOpen,
} from "../../../redux/slices/localSettings.slice";

interface Props {
  description: string;
  shortcut?: string;
  placement?: Placement;
  children: any;

  /** If true, sets max-width to 13rem */
  maxWidth?: boolean;
}

function Tooltip({
  description,
  shortcut,
  placement,
  children,
  maxWidth,
}: Props) {
  const hasTooltipOpen = useAppSelector(selectHasTooltipOpen);
  const dispatch = useAppDispatch();

  const _id = useRef(uuidv4());

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [show, setShow] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement ?? "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  return (
    <>
      <div
        onMouseEnter={async () => {
          setShow(true);
          delay(800).then(() => {
            if (!hasTooltipOpen) {
              dispatch(addTooltipOpen(_id.current));
            }
          });
        }}
        onMouseLeave={() => {
          setShow(false);
          delay(800).then(() => {
            if (!show) {
              dispatch(removeTooltipOpen(_id.current));
            }
          });
        }}
        ref={setReferenceElement}
      >
        {children}
      </div>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {show && (
            <div
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-50"
            >
              <motion.div
                className={`inline-block px-2 py-1 text-xxs tooltip ${
                  maxWidth ? "max-w-[13rem]" : ""
                }`}
                initial={{
                  opacity: 1,
                  scale: 0.9,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    delay: hasTooltipOpen ? 0 : 0.8,
                  },
                }}
                exit={{
                  opacity: 0,
                  scale: 0.9,
                  transition: {
                    delay: 0,
                  },
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.15,
                }}
              >
                {description}
                {shortcut && (
                  <span className="shortcuts">
                    {/* <span className="m-2">·</span> */}
                    <span>{shortcut}</span>
                  </span>
                )}
              </motion.div>
            </div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
}

export default Tooltip;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
