import moment from "moment";

function General() {
  return (
    <section className="grow">
      <h1 className="p-6 text-lg font-normal">Changelog</h1>

      <hr className="big" />

      <article className="m-6 prose">
        <h1>Alpha release</h1>
        <time>
          {moment(Date.UTC(2022, 8, 6)).locale("en").format("D MMM YYYY")}
        </time>
        <br />
        <br />
        <p>Hi everyone!</p>
        <p>
          I'm excited to finaly announce the first alphaversion of CreateTodo.
          Lot's of effort has been put into scetching out the app's features and
          building the infrastructure.
        </p>
        <p>
          The main tech stack includes React, Redux, Tailwind and Firestore.
        </p>
        <br />
        <p>Some of the features includes:</p>
        <ul>
          <li>
            <b>Todos</b> — Create, edit and delete todos, of course :).
          </li>
          <li>
            <b>Todos</b> — Create, edit and delete todos, of course :).
          </li>
          <li>
            <b>Context menu</b> — Right click a todo to edit or delete it.
          </li>
          <li>
            <b>Undo and redo</b> — Shortcuts included.
          </li>
          <li>
            <b>Light and dark mode</b> — Automatically switches between the dark
            or light mode based on your system preferences. You can also pick
            the mode you prefer.
          </li>
        </ul>
      </article>
    </section>
  );
}

export default General;
