import { createAction, Middleware, PayloadAction } from "@reduxjs/toolkit";
import { setPageSettings, userSettingsUpdated } from "../slices/userSettings.slice";
import { middleWareFunction, RootState } from "../store";

export const toggleFullWidth = createAction("todos/toggleFullWidth");
export const toggleCenterTodos = createAction("todos/toggleCenterTodos");
export const toggleLeftSidebar = createAction("todos/toggleLeftSidebar");
export const toggleShowCreatedDateOfTodos = createAction("todos/toggleShowCreatedDateOfTodos");
export const toggleCreateTodoButton = createAction("todos/toggleCreateTodoButton");

/**
 * userSettingsMiddleware exists so that it can rewrite each action
 */
export const userSettingsMiddleware: Middleware<{}, RootState> =
  (api) => (next) => (action: PayloadAction) => {
    switch (action.type) {
      case toggleFullWidth.type:
        _toggleFullWidth(api, next, action);
        break;
      case toggleCenterTodos.type:
        _toggleCenterTodos(api, next, action);
        break;
      case toggleLeftSidebar.type:
        _toggleLeftSidebar(api, next, action);
        break;
      case toggleShowCreatedDateOfTodos.type:
        _toggleShowCreatedDateOfTodos(api, next, action);
        break;
      case toggleCreateTodoButton.type:
        _toggleCreateTodoButton(api, next, action);
        break;
      case setPageSettings.type:
        _setPageSettings(api, next, action);
        break;
    }

    return next(action);
  };

const _toggleFullWidth: middleWareFunction = (api, next, action) => {
  api.dispatch(
    userSettingsUpdated({
      fullWidth: !api.getState().userSettings!.fullWidth
    })
  );
};

const _toggleCenterTodos: middleWareFunction = (api, next, action) => {
  api.dispatch(
    userSettingsUpdated({
      centerTodos: !api.getState().userSettings!.centerTodos
    })
  );
};

const _toggleLeftSidebar: middleWareFunction = (api, next, action) => {
  api.dispatch(
    userSettingsUpdated({
      leftSidebar: !api.getState().userSettings!.leftSidebar
    })
  );
};

const _toggleShowCreatedDateOfTodos: middleWareFunction = (api, next, action) => {
  api.dispatch(
    userSettingsUpdated({
      showCreatedDateOfTodos: !api.getState().userSettings!.showCreatedDateOfTodos
    })
  );
};

const _toggleCreateTodoButton: middleWareFunction = (api, next, action) => {
  api.dispatch(
    userSettingsUpdated({
      createTodoButton: !api.getState().userSettings!.createTodoButton
    })
  );
};

// TODO: Uppdate specific pagesettings and not all...
const _setPageSettings: middleWareFunction = (api, next, action) => {
  api.dispatch(
    userSettingsUpdated({
      pageSettings: [
        ...(api.getState().userSettings!.pageSettings?.filter(
          (obj) => obj.id !== action.payload.id
        ) ?? []),
        { ...action.payload },
      ]
    })
  );
};