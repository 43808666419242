import { auth } from "../../firebase";
import { newEntitiy } from "./entity";
import TodoStateEntity from "./todoStateEntity";

/** A todo. */
export interface Todo extends TodoStateEntity {
  activeAt: number;

  /** The user to whom the todo is assigned to */
  assigneeId?: string;

  /** Status equals to canceled. */
  canceledAt?: number;

  /** Status equals to done. */
  completedAt?: number;

  creatorId: string;

  description: string;

  dueDate?: number;

  labelIds: string[];

  priority: number;

  /** Status equals to doing. */
  startedAt?: number;

  /** Status equals to deleted. */
  trashedAt?: number;
}

export enum TodoState {
  backlog,
  todo,
  doing,
  done,
}

/** Creates a new todo */
export function newTodo({
  activeAt,
  archivedAt,
  createdAt,
  id,
  updatedAt,
  assigneeId,
  canceledAt,
  collapsed,
  completedAt,
  creatorId,
  description,
  dueDate,
  labelIds,
  name,
  parentId,
  priority,
  projectId,
  sortOrder,
  startedAt,
  trashedAt,
  todoState,
}: {
  activeAt?: number;
  archivedAt?: number;
  createdAt?: number;
  id?: string;
  updatedAt?: number;
  assigneeId?: string;
  canceledAt?: number;
  collapsed?: boolean;
  completedAt?: number;
  creatorId?: string;
  description?: string;
  dueDate?: number;
  labelIds?: string[];
  name: string;
  parentId?: string;
  priority?: number;
  projectId?: string;
  sortOrder?: number;
  startedAt?: number;
  trashedAt?: number;
  todoState?: TodoState;
}) {
  const currentUserId = auth.currentUser?.uid;

  return {
    ...newEntitiy({
      archivedAt: archivedAt,
      createdAt: createdAt,
      id: id,
      name: name,
      updatedAt: updatedAt,
    }),
    ...{
      activeAt: activeAt ?? todoState === TodoState.todo ? Date.now() : undefined,
      assigneeId: assigneeId,
      canceledAt: canceledAt,
      collapsed: collapsed,
      completedAt: completedAt ?? todoState === TodoState.done ? Date.now() : undefined,
      creatorId: creatorId ?? currentUserId,
      description: description,
      dueDate: dueDate,
      labelIds: labelIds,
      parentId: parentId,
      priority: priority,
      projectId: projectId,
      sortOrder: sortOrder,
      startedAt: startedAt ?? todoState === TodoState.doing ? Date.now() : undefined,
      trashedAt: trashedAt,
    },
  } as Todo;
}

export const getTodoState = (todo: Todo) =>
  todo.completedAt ? TodoState.done :
    todo.startedAt ? TodoState.doing :
      todo.activeAt ? TodoState.todo :
        TodoState.backlog

export const todoStateChanges = (newState: TodoState, todo?: Todo) => {
  switch (newState) {
    case TodoState.done:
      return { completedAt: todo?.completedAt ?? Date.now() }
    case TodoState.doing:
      return { completedAt: undefined, startedAt: todo?.startedAt ?? Date.now() }
    case TodoState.todo:
      return { completedAt: undefined, startedAt: undefined, activeAt: todo?.activeAt ?? Date.now() }
    case TodoState.backlog:
      return { completedAt: undefined, startedAt: undefined, activeAt: undefined }
    default:
      return null;
  }
}