import { createAction, Middleware, Update } from "@reduxjs/toolkit";
import { FlattenedItem, TreeItem } from "../../components/houi/Sortable/types";
import { buildTree } from "../../components/houi/Sortable/utilities";
import {
  selectTodo,
  selectFlatNodeTodos,
  selectTodos,
  selectAllTodoIds,
  getChildrenIdsOfIds,
} from "../selectors/todosSelectors";
import { todosDeleted, todosUpdated, todoUpdated } from "../slices/todos.slice";
import { middleWareFunction, RootState } from "../store";
import { Todo } from "../types";
import { UndoAction } from "../types/undoRedo";

export const updateTodoName = createAction(
  "todos/updateTodoName",
  function prepare(id: string, name: string) {
    return {
      payload: {
        id,
        changes: {
          name,
        },
      },
    };
  }
);

export const updateSortorder = createAction<TreeItem[]>("todos/updateSortorder");
export const toggleTodo = createAction<string>("todos/toggleTodo");
export const toggleCollapsed = createAction<string>("todos/toggleCollapsed");
export const deleteTodo = createAction<string>("todos/deleteTodo");
export const deleteAllActiveTodos = createAction<null>(
  "todos/deleteAllActiveTodos"
);
export const deleteAllDoneTodos = createAction<null>(
  "todos/deleteAllDoneTodos"
);
export const deleteAllTodos = createAction<null>("todos/deleteAllTodos");

/**
 * todosMiddleware exists so that it can rewrite each action
 */
export const todosMiddleware: Middleware<{}, RootState> =
  (api) => (next) => (action: UndoAction) => {
    switch (action.type) {
      case updateTodoName.type:
        _updateTodoName(api, next, action);
        break;
      case toggleTodo.type:
        _toggleTodo(api, next, action);
        break;
      case toggleCollapsed.type:
        _toggleCollapsed(api, next, action);
        break;
      case updateSortorder.type:
        _updateSortorder(api, next, action);
        break;
      case deleteTodo.type:
        _deleteTodo(api, next, action);
        break;
      case deleteAllTodos.type:
        _deleteAllTodos(api, next, action);
        break;
      case deleteAllActiveTodos.type:
        _deleteAllActiveTodos(api, next, action);
        break;
      case deleteAllDoneTodos.type:
        _deleteAllDoneTodos(api, next, action);
        break;
    }

    return next(action);
  };

const _updateTodoName: middleWareFunction = (api, next, action) => {
  api.dispatch(todoUpdated(action.payload));
};

const _toggleTodo: middleWareFunction = (api, next, action) => {
  const todo = selectTodo(api.getState(), action.payload);
  if (todo) {
    api.dispatch(
      todoUpdated({
        id: action.payload,
        changes: {
          completedAt: todo.completedAt ? undefined : Date.now(),
        },
      })
    );
  }
};

const _toggleCollapsed: middleWareFunction = (api, next, action) => {
  const todo = selectTodo(api.getState(), action.payload);
  if (todo) {
    api.dispatch(
      todoUpdated({
        id: action.payload,
        changes: {
          collapsed: todo.collapsed === true ? undefined : true,
        },
      })
    );
  }
};

const _updateSortorder: middleWareFunction = (api, next, action) => {
  const payload = action.payload as unknown as FlattenedItem[];

  const updates = payload.map<Update<Todo>>((item: FlattenedItem, index) => {
    return {
      id: item.id,
      changes: {
        sortOrder: index,
        parentId: item.parentId ?? undefined,
        collapsed: item.collapsed,
      },
    };
  });

  if (updates.length > 0) {
    api.dispatch(todosUpdated(updates));
  }
};

const _deleteTodo: middleWareFunction = (api, next, action: UndoAction) => {
  const state = api.getState();

  const itemsToBeDeleted = selectFlatNodeTodos(
    selectTodos(state).map((todo) => {
      return { id: todo.id, parentId: todo.parentId } as FlattenedItem;
    }),
    action.payload
  ).map((nodeTodo) => nodeTodo.id);

  api.dispatch(todosDeleted(itemsToBeDeleted));
};

const _deleteAllTodos: middleWareFunction = (api, next, action: UndoAction) => {
  api.dispatch(todosDeleted(selectAllTodoIds(api.getState())));
  return next(action);
};

//! Todo: Bug: Not all children gets deleted !
const _deleteAllActiveTodos: middleWareFunction = (
  api,
  next,
  action: UndoAction
) => {
  const todos = selectTodos(api.getState());
  const ids = todos.filter((todo) => !todo.completedAt).map((todo) => todo.id);
  const childrenIds = getChildrenIdsOfIds(
    buildTree(
      todos.map(
        (todo) => ({ id: todo.id, parentId: todo.parentId } as FlattenedItem)
      )
    ),
    ids
  );
  api.dispatch(todosDeleted([...ids, ...childrenIds]));
};

//! Todo: Bug: Not all children gets deleted !
const _deleteAllDoneTodos: middleWareFunction = (
  api,
  next,
  action: UndoAction
) => {
  const todos = selectTodos(api.getState());
  const ids = todos.filter((todo) => todo.completedAt).map((todo) => todo.id);
  const childrenIds = getChildrenIdsOfIds(
    buildTree(
      todos.map(
        (todo) => ({ id: todo.id, parentId: todo.parentId } as FlattenedItem)
      )
    ),
    ids
  );
  console.log([...ids, ...childrenIds])
  api.dispatch(todosDeleted([...ids, ...childrenIds]));
};

// updateSortorder: (state, action: PayloadAction<FlattenedItem[]>) => {
//   todosAdapter.updateMany(
//     state,
//     action.payload.map<Update<Todo>>((item: FlattenedItem, index) => {
//       return {
//         id: item.id,
//         changes: {
//           sortOrder: index,
//           parentId: item.parentId ?? undefined,
//           collapsed: item.collapsed,
//         },
//       };
//     })
//   );
// },

