import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { RaisedButton } from ".";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  leading?: any;
}

const SelectionButton = forwardRef<HTMLButtonElement, Props>(
  ({ type = "button", className, children, ...props }, ref) => {
    return (
      <RaisedButton
        className={"inline-flex items-center " + className}
        ref={ref}
        type={type}
        {...props}
      >
        {props.leading && (
          <div className={`grow-0 -ml-0.5 mr-1 my-auto text-sm`}>
            {props.leading}
          </div>
        )}
        <div className={`grow`}>{children}</div>

        <div className={`grow-0`}>
          <Icon
            path={mdiChevronDown}
            className="inline-block w-4 h-4 ml-1 disabledTextColor grow-0"
          />
        </div>
      </RaisedButton>
    );
  }
);

export default SelectionButton;
