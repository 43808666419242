import React, { forwardRef, HTMLAttributes, memo, useEffect } from "react";
import classNames from "classnames";

import styles from "./TreeItem.module.scss";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import TodoItem from "../../../TodoItem";
import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from "@dnd-kit/core";
import SidenavIconButton from "../../../../app_layout/sidenav/sidenav-icon-button";

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
  indicator?: boolean;
  indentationWidth: number;
  id: string;
  transform?: import("@dnd-kit/utilities").Transform | null;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
}

const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      listeners,
      attributes,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      id,
      transform,
      wrapperRef,
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      if (!ghost) {
        return;
      }
      document.body.style.setProperty("cursor", "grabbing");
      return () => {
        document.body.style.setProperty("cursor", "");
      };
    }, [ghost]);

    return (
      <li
        ref={wrapperRef}
        key={"li-" + id}
        style={
          {
            "--spacing": `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        className={`
          ${
            ghost
              ? "highlight ghost "
              : " box-content border rounded border-transparent"
          } 
              ${classNames(
                styles.Wrapper,
                clone && styles.clone,
                clone && "ripple-active",
                ghost && styles.ghost,
                disableSelection && styles.disableSelection,
                disableInteraction && styles.disableInteraction
              )}
        `}
      >
        <div className={styles.TreeItem} style={style}>
          <TodoItem
            clone={clone}
            ref={ref}
            key={"TodoItem-" + id}
            id={id}
            listeners={listeners}
            attributes={attributes}
            collapseBtn={
              onCollapse && (
                <SidenavIconButton
                  icon={collapsed ? mdiChevronDown : mdiChevronUp}
                  onClick={onCollapse}
                  stopPropagation
                  highlightOnHover
                  disableTextColor
                />
              )
            }
          />
          {/* {!clone && onRemove && <Remove onClick={onRemove} />} */}
          {clone && childCount && childCount > 1 ? (
            <span className={styles.Count}>{childCount}</span>
          ) : null}
        </div>
      </li>
    );
  }
);

export default memo(TreeItem);
