import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UndoRedo } from "../types";

const undoRedoSlice = createSlice<UndoRedo, SliceCaseReducers<UndoRedo>>({
  name: "UndoRedo",
  initialState: { actions: [], index: -1 },
  reducers: {
    addUndoAction: (state, action) => {
      let actions = state.actions;
      /** Makes sure when adding an action that future actions from the current index gets removed exists. */
      if (state.index < actions.length - 1) {
        console.log("slice -- end is: " + (state.index - actions.length + 1));
        actions = actions.slice(0, state.index - (actions.length - 1));
      }

      actions = [...actions, action.payload];

      return {
        actions: actions,
        index: actions.length - 1 <= -1 ? -1 : actions.length - 1,
      };
    },
    undo: (state) => void (state.index > -1 ? state.index-- : state.index),
    redo: (state) => {
      if (state.index < state.actions.length - 1) {
        state.index = state.index + 1;
      }
    },
    undoRedoReset: (state) => {
      state.actions = [];
      state.index = -1;
    }
  },
});

export const { addUndoAction, undo, redo, undoRedoReset } = undoRedoSlice.actions;

export default undoRedoSlice.reducer;

export const canUndo = (state: RootState) => state.undoRedo.index <= -1;

export const canRedo = (state: RootState) =>
  state.undoRedo.index >= state.undoRedo.actions.length - 1;
