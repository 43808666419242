import { ButtonHTMLAttributes, forwardRef } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  mini?: boolean;
}

const RaisedButton = forwardRef<HTMLButtonElement, Props>(
  ({ disabled, className, children, mini, ...props }, ref) => {
    return (
      <button
        onMouseDown={(e) => e.preventDefault()}
        className={`border border-solid bg-shark-light-100 dark:bg-shark-500 font-medium 
        ${
          disabled
            ? "border-b-shark-500/25 dark:border-shark-500 cursor-default"
            : "shadow border-t-shark-200/25 border-x-shark-200/25 border-b-shark-400/25 dark:border-t-shark-300 dark:border-x-shark-300 dark:border-b-shark-400 ripple-filled "
        }
        ${mini ? "px-1 h-5" : "px-2 h-7"}
        ${className}
        transition-colors ${disabled ? "disabledTextColor" : "textColor"}
        `}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default RaisedButton;
