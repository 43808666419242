import {
  DraggableAttributes,
  DraggableSyntheticListeners,
} from "@dnd-kit/core";
import {
  mdiCheck,
  mdiCircleSmall,
  mdiLightningBoltOutline,
  mdiSquareRoundedOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { AnimatePresence, motion } from "framer-motion";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { osName } from "react-device-detect";
import useCombinedRefs from "../../../hooks/use_combined_refs";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  deleteTodo,
  toggleTodo,
} from "../../../redux/middleware/todosMiddleware";
import { selectTodo } from "../../../redux/selectors/todosSelectors";
import {
  addTodoRecentlyToggled,
  removeTodoRecentlyToggled,
} from "../../../redux/slices/localSettings.slice";
import { selectShowCreatedDateOfTodos } from "../../../redux/slices/userSettings.slice";
import { todoUpdated } from "../../../redux/slices/todos.slice";
import {
  getTodoState,
  todoStateChanges,
  TodoState,
} from "../../../redux/types/todo";
import CreateTodoModal from "../../app_layout/CreateTodoModal";
import Menu from "../../loui/Menu";
import MenuButton from "../../loui/Menu/MenuButton";
import Submenu from "../../loui/Menu/Submenu";
import Modal from "../../loui/Modal";
import Checkbox from "../../ui/Checkbox";
import ParentText from "./parent_text";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
  collapseBtn?: any;
  flat?: boolean;
  number?: number;
  focus?: boolean;
  clone?: boolean;
  setFocus?: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const TodoItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const todo = useAppSelector((state) => selectTodo(state, props.id));
  const showCreatedDateOfTodos = useAppSelector(selectShowCreatedDateOfTodos);

  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const innerTodoRef = useRef<HTMLDivElement | null>(null);
  const todoRef = useCombinedRefs(innerTodoRef, ref);

  const menuContent = useCallback(
    (setOpen2: any) => (
      <>
        <Submenu
          content={(setOpen: any) => (
            <>
              <MenuButton
                leading={<Icon className="w-4 h-4" path={mdiCircleSmall} />}
                title="Backlog"
                disabled={getTodoState(todo!) === TodoState.backlog}
                handlerCallback={async () => {
                  console.log(JSON.stringify(todo));
                  dispatch(
                    todoUpdated({
                      id: todo!.id,
                      changes: todoStateChanges(TodoState.backlog),
                    })
                  );
                  dispatch(addTodoRecentlyToggled(props.id));
                  await delay(500);
                  dispatch(removeTodoRecentlyToggled(props.id));
                }}
                setOpen={setOpen}
              />
              <MenuButton
                leading={
                  <Icon className="w-4 h-4" path={mdiSquareRoundedOutline} />
                }
                title="Todo"
                disabled={getTodoState(todo!) === TodoState.todo}
                handlerCallback={async () => {
                  dispatch(
                    todoUpdated({
                      id: todo!.id,
                      changes: todoStateChanges(TodoState.todo),
                    })
                  );
                  dispatch(addTodoRecentlyToggled(props.id));
                  await delay(500);
                  dispatch(removeTodoRecentlyToggled(props.id));
                }}
                setOpen={setOpen}
              />
              <MenuButton
                leading={
                  <Icon className="w-4 h-4" path={mdiLightningBoltOutline} />
                }
                title="Doing"
                disabled={getTodoState(todo!) === TodoState.doing}
                handlerCallback={async () => {
                  dispatch(
                    todoUpdated({
                      id: todo!.id,
                      changes: todoStateChanges(TodoState.doing),
                    })
                  );
                  dispatch(addTodoRecentlyToggled(props.id));
                  await delay(500);
                  dispatch(removeTodoRecentlyToggled(props.id));
                }}
                setOpen={setOpen}
              />
              <MenuButton
                leading={<Icon className="w-4 h-4 p-0.5" path={mdiCheck} />}
                title="Done"
                disabled={getTodoState(todo!) === TodoState.done}
                handlerCallback={async () => {
                  dispatch(
                    todoUpdated({
                      id: todo!.id,
                      changes: todoStateChanges(TodoState.done),
                    })
                  );
                  dispatch(addTodoRecentlyToggled(props.id));
                  await delay(500);
                  dispatch(removeTodoRecentlyToggled(props.id));
                }}
                setOpen={setOpen}
              />
            </>
          )}
        >
          <MenuButton title="Status" hasSubMenu />
        </Submenu>
        <hr />
        <MenuButton
          title="Edit todo"
          handlerCallback={() => setOpen(true)}
          setOpen={setOpen2}
          shortcuts="E"
        />
        <hr />
        <MenuButton
          title="Delete todo"
          handlerCallback={() => dispatch(deleteTodo(props.id))}
          setOpen={setOpen2}
          shortcuts={osName === "Mac OS" ? "⌘⌫" : "Ctrl+Del"}
        />
      </>
    ),
    [todo]
  );

  useEffect(() => {
    props.focus ? todoRef.current?.focus() : todoRef.current?.blur();
  }, [props.focus]);

  const handleSelect = useCallback(() => {
    props.setFocus?.(props.number ?? 0);
  }, [props.number, props.setFocus]);

  return (
    <>
      <AnimatePresence>
        {todo && (
          <>
            <Modal
              Content={(any: any) =>
                CreateTodoModal({ ...any, ...{ id: props.id } })
              }
              open={open}
              setOpen={setOpen}
            ></Modal>
            <Menu contextMenu wrapperDisplay="block" content={menuContent}>
              <motion.div
                className={`relative ${props.clone ? "clone" : ""}`}
                animate={{ height: "auto", opacity: 1 }}
                exit={{
                  height: 0,
                  overflow: "hidden",
                  opacity: 0,
                }}
                transition={{ ease: "easeOut", delay: 0.5, duration: 0.15 }}
              >
                <div
                  className={`absolute flex items-center justify-center top-0 z-10 checkbox ${
                    props.clone
                      ? "left-px h-[38px] w-[38px]"
                      : "left-0 h-[40px] w-[40px]"
                  }`}
                >
                  <Checkbox
                    state={getTodoState(todo)}
                    onChange={async (event) => {
                      dispatch(
                        todoUpdated({
                          id: todo.id,
                          changes: todoStateChanges(event),
                        })
                      );
                      dispatch(addTodoRecentlyToggled(props.id));
                      await delay(500);
                      dispatch(removeTodoRecentlyToggled(props.id));
                    }}
                  />
                  {/* <MCCheckbox
                    tabIndex={-1}
                    checked={todo.completedAt !== undefined}
                    onChange={async () => {
                      void dispatch(
                        todoUpdated({
                          id: todo.id,
                          changes: {
                            completedAt: todo.completedAt
                              ? undefined
                              : Date.now(),
                          },
                        })
                      );
                      dispatch(addTodoRecentlyToggled(props.id));
                      await delay(500);
                      dispatch(removeTodoRecentlyToggled(props.id));
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                  /> */}
                </div>
                {props.collapseBtn && (
                  <div className="collapseBtn absolute -left-9 w-7 h-7 top-1.5 ml-0.5">
                    {props.collapseBtn}
                  </div>
                )}
                <div
                  key={"todo_item-" + props.id}
                  style={props.style}
                  {...props.listeners}
                  {...props.attributes}
                  ref={todoRef}
                  className={`relative flex items-center pl-11 pr-3 py-2 rounded border-transparent todo-item ${
                    props.clone ? "min-h-[38px]" : "min-h-[40px] ripple"
                  }`}
                  tabIndex={0}
                  role="button"
                  onClick={handleSelect}
                  onDoubleClick={() => setOpen(true)}
                  // onKeyPress={handleSelect}
                  onFocus={() => props.setFocus?.(props.number)}
                  onBlur={() => props.setFocus?.(undefined)}
                  onKeyDown={(e) => {
                    const key = e.key.toLowerCase();
                    if (key === "e") {
                      if (open !== true) setOpen(true);
                    } else if (key === " ") {
                      dispatch(toggleTodo(props.id));
                      e.preventDefault();
                    } else if (
                      (osName === "Mac OS" ? e.metaKey : e.ctrlKey) &&
                      key === "backspace"
                    ) {
                      dispatch(deleteTodo(props.id));
                    } else {
                      props.listeners?.onKeyDown?.(e);
                    }
                  }}
                >
                  <div className="mr-2 grow">
                    {props.flat && todo.parentId && (
                      <ParentText id={todo.parentId} />
                    )}
                    <p
                      className={`transition-color break-words`}
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {todo.name ?? ""}
                    </p>
                  </div>
                  <div className="flex grow-0 whitespace-nowrap item-center">
                    {/* {todo.completedAt && (
                      <Tooltip description="Date when todo was completed">
                        <span className="mx-2 font-mono text-habitory-400 text-xxs">
                          {moment(todo.completedAt).format("D MMM")}
                        </span>
                      </Tooltip>
                    )} */}
                    {/* {showCreatedDateOfTodos && (
                      <Tooltip description="Date when todo was created">
                        <span
                          className={`font-mono text-xxs disabledTextColor`}
                        >
                          {moment(todo.createdAt).format("D MMM")}
                        </span>
                      </Tooltip>
                    )} */}
                  </div>
                </div>
              </motion.div>
            </Menu>
          </>
        )}
      </AnimatePresence>
    </>
  );
});

export default memo(TodoItem);

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
