import { useEffect } from "react";

export default function useCombinedRefs<T>(innerRef: React.MutableRefObject<T>, fwdRef: React.ForwardedRef<T>) {
    useEffect(() => {
        [innerRef, fwdRef].forEach(ref => {
            if (ref) {
                if (typeof ref === 'function') {
                    ref(innerRef.current || null)
                } else {
                    ref.current = innerRef.current || null
                }
            }
        })
    }, [innerRef, fwdRef]);
    return innerRef;
}