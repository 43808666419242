import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LocalSettings } from "../types";

const localSettingsSlice = createSlice<
  LocalSettings | undefined,
  SliceCaseReducers<LocalSettings | undefined>
>({
  name: "localSettings",
  initialState: {
    userLoaded: false,
    userSettingsLoaded: false,
    todosLoaded: false,
    tooltipsOpened: [],
    todosRecentlyToggled: [],
    disableGlobalShortcuts: false,
  } as LocalSettings,
  reducers: {
    setLocalSettings: (state, action: PayloadAction<LocalSettings>) => {
      return action.payload;
    },
    updateLocalSettings: (state, action: PayloadAction<Partial<LocalSettings>>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    removeLocalSettings: () => {
      return undefined;
    },
    addTooltipOpen: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tooltipsOpened: [...state!.tooltipsOpened!, action.payload],
      };
    },
    removeTooltipOpen: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        tooltipsOpened: state?.tooltipsOpened?.filter(
          (val) => val !== action.payload
        ),
      };
    },
    addTodoRecentlyToggled: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        todosRecentlyToggled: [...state!.todosRecentlyToggled!, action.payload],
      };
    },
    removeTodoRecentlyToggled: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        todosRecentlyToggled: state?.todosRecentlyToggled?.filter(
          (val) => val !== action.payload
        ),
      };
    },
    setDisableGlobalShortcuts(state, action: PayloadAction<boolean>) {
      state!.disableGlobalShortcuts = action.payload;
    },
  },
});

export const {
  setLocalSettings,
  updateLocalSettings,
  removeLocalSettings,
  addTooltipOpen,
  removeTooltipOpen,
  addTodoRecentlyToggled,
  removeTodoRecentlyToggled,
  setDisableGlobalShortcuts,
} = localSettingsSlice.actions;

export default localSettingsSlice.reducer;

export const selectLocalSettings = (state: RootState) => state.localSettings;
export const selectIsLoading = (state: RootState) =>
  state.localSettings?.userLoaded === false || state.localSettings?.userSettingsLoaded === false || state.localSettings?.todosLoaded === false;
export const selectHasTooltipOpen = (state: RootState) =>
  state.localSettings!.tooltipsOpened!.length > 0;
export const selectHasTodoRecentlyToggled = (state: RootState) =>
  state.localSettings!.todosRecentlyToggled!.length > 0;
export const selectDisableGlobalShortcuts = (state: RootState) =>
  state.localSettings?.disableGlobalShortcuts;
