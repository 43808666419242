import { ButtonHTMLAttributes, forwardRef } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const FlatButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, ...props }, ref) => {
    return (
      <button
        onMouseDown={(e) => void e.preventDefault()}
        className={
          "font-medium px-2 h-7  inline-flex items-center textColor ripple " +
          className
        }
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export default FlatButton;
