import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectLeftSidebar,
  selectFullWidth,
  selectToggleCreateTodoButton,
  selectCenterTodos,
  userSettingsRemoved,
} from "../../../redux/slices/userSettings.slice";
import useMousetrap from "../../../hooks/useMousetrap";
import Menu from "../../loui/Menu";
import MenuButton from "../../loui/Menu/MenuButton";
import { osName } from "react-device-detect";
import {
  canUndo,
  canRedo,
  redo,
  undo,
  undoRedoReset,
} from "../../../redux/slices/undoRedo.slice";
import { useEventListener } from "../../../hooks";
import { selectUser, userRemoved } from "../../../redux/slices/user.slice";
import { useState } from "react";
import Modal from "../../loui/Modal";
import SettingsModal from "./SettingsModal";
import { FlatButtonWithArrow } from "../../ui";
import { authSignOut } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import {
  toggleFullWidth,
  toggleLeftSidebar,
  toggleCreateTodoButton,
  toggleCenterTodos,
} from "../../../redux/middleware/userSettingsMiddleware";
import { todosRemoved } from "../../../redux/slices/todos.slice";
import { updateLocalSettings } from "../../../redux/slices/localSettings.slice";

export default function UserButton() {
  const user = useAppSelector(selectUser);

  const fullWidth = useAppSelector(selectFullWidth);
  const centerTodos = useAppSelector(selectCenterTodos);
  const leftSidebar = useAppSelector(selectLeftSidebar);
  const createTodoButton = useAppSelector(selectToggleCreateTodoButton);
  // const showCreatedDateOfTodos = useAppSelector(selectShowCreatedDateOfTodos);
  const dispatch = useAppDispatch();

  let navigate = useNavigate();

  // useMousetrap("c", () => setShowCreateTodoModal(true));
  useMousetrap("f", () => dispatch(toggleFullWidth(null)));
  useMousetrap("mod+b", () => dispatch(toggleLeftSidebar(null)));
  useMousetrap("k c", () => dispatch(toggleCreateTodoButton(null)));

  const _canUndo = useAppSelector(canUndo);
  const _canRedo = useAppSelector(canRedo);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      (osName === "Mac OS" ? event.metaKey : event.ctrlKey) &&
      event.key === ","
    ) {
      event.preventDefault();
      event.stopPropagation();
      setShowSettingsModal(true);
    } else if (
      (osName === "Mac OS" ? event.metaKey : event.ctrlKey) &&
      event.shiftKey &&
      event.key === "z"
    ) {
      event.preventDefault();
      event.stopPropagation();
      dispatch(redo(null));
    } else if (
      (osName === "Mac OS" ? event.metaKey : event.ctrlKey) &&
      event.key === "z"
    ) {
      event.preventDefault();
      event.stopPropagation();
      dispatch(undo(null));
    } else if (!leftSidebar && event.key === "Escape") {
      dispatch(toggleLeftSidebar(null));
    }
  };

  useEventListener("keydown", handleKeyDown);

  const menuContent = (
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <>
      <MenuButton
        title="Settings"
        shortcuts={osName === "Mac OS" ? "⌘," : "Ctrl+,"}
        handlerCallback={() => setShowSettingsModal(true)}
        setOpen={setOpen}
      />
      <hr />
      <MenuButton
        title="Full width"
        shortcuts="F"
        // dontClose: true,
        handlerCallback={() => dispatch(toggleFullWidth(null))}
        toggle={fullWidth}
        // setOpen={setOpen}
      />
      <MenuButton
        title="Center todos"
        // dontClose: true,
        handlerCallback={() => dispatch(toggleCenterTodos(null))}
        toggle={centerTodos}
        // setOpen={setOpen}
        disabled={fullWidth}
      />
      <MenuButton
        title="Left sidebar"
        shortcuts="⌘B"
        handlerCallback={() => dispatch(toggleLeftSidebar(null))}
        toggle={leftSidebar}
      />
      <MenuButton
        title="Create todo button"
        shortcuts="K C"
        handlerCallback={() => dispatch(toggleCreateTodoButton(null))}
        toggle={createTodoButton}
      />
      {/* <MenuButton
        title="Show created date"
        handlerCallback={() => dispatch(toggleShowCreatedDateOfTodos(null))}
        toggle={showCreatedDateOfTodos}
      /> */}
      <hr />
      <MenuButton
        title="Undo"
        shortcuts={osName === "Mac OS" ? "⌘Z" : "Ctrl+Z"}
        handlerCallback={() => dispatch(undo(null))}
        disabled={_canUndo}
      />
      <MenuButton
        title="Redo"
        shortcuts={osName === "Mac OS" ? "⇧⌘Z" : "Ctrl+Shift+Z"}
        handlerCallback={() => dispatch(redo(null))}
        disabled={_canRedo}
      />
      {/* <hr />
      <MenuButton title="Archive" shortcuts={"G A"} /> */}
      {/* <MenuButton title="Bin" shortcuts={"G B"} /> */}
      <hr />
      <MenuButton
        title="Sign out"
        setOpen={setOpen}
        handlerCallback={() => {
          authSignOut();

          dispatch(
            updateLocalSettings({
              userLoaded: false,
              userSettingsLoaded: false,
              todosLoaded: false,
            })
          );

          dispatch(userRemoved(null));
          dispatch(userSettingsRemoved(null));
          dispatch(todosRemoved(null));
          dispatch(undoRedoReset(null));
          navigate("/");
        }}
      />
    </>
  );

  return (
    <>
      <Modal
        width={800}
        height={600}
        Content={SettingsModal}
        open={showSettingsModal}
        setOpen={setShowSettingsModal}
      ></Modal>
      <Menu content={menuContent}>
        <FlatButtonWithArrow className="whitespace-nowrap">
          <div className="inline-flex items-center justify-center w-5 h-5 my-auto mr-[7px] -ml-[5px] rounded-sm grow-0 bg-cadet-blue-800 dark:bg-cadet-blue-dark-text-color">
            <span className="text-xs text-cadet-blue-dark-text-color dark:text-cadet-blue-800">
              <small>{user?.name[0].toUpperCase()}</small>
            </span>
          </div>
          {user?.name.substring(0, 14)}
        </FlatButtonWithArrow>
      </Menu>
    </>
  );
}
