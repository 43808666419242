import {
  mdiCheck,
  mdiCircleSmall,
  mdiLightningBoltOutline,
  mdiSquareRoundedOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { AnimatePresence, motion } from "framer-motion";
import { memo, useEffect, useState } from "react";
import useDidMountEffect from "../../hooks/useDidMountEffects";
import { TodoState } from "../../redux/types/todo";
import Menu from "../loui/Menu";
import MenuButton from "../loui/Menu/MenuButton";

interface Props {
  state: TodoState;
  onChange(event: TodoState): void;
}

function Checkbox(props: Props) {
  const [checked, setChecked] = useState<TodoState>(props.state);

  useEffect(() => {
    if (props.state !== checked) setChecked(props.state);
  }, [props.state]);

  useDidMountEffect(() => {
    props.onChange(checked);
  }, [checked]);

  return (
    <Menu
      wrapperDisplay="inline-flex"
      placement="right-start"
      offset={[-5 - 28 * checked, 4]}
      content={(setOpen: any) => (
        <>
          <MenuButton
            leading={<Icon className="w-4 h-4" path={mdiCircleSmall} />}
            title="Backlog"
            disabled={checked === TodoState.backlog}
            handlerCallback={() => {
              setChecked(TodoState.backlog);
            }}
            setOpen={setOpen}
          />
          <MenuButton
            leading={
              <Icon className="w-4 h-4" path={mdiSquareRoundedOutline} />
            }
            title="Todo"
            disabled={checked === TodoState.todo}
            handlerCallback={() => setChecked(TodoState.todo)}
            setOpen={setOpen}
          />
          <MenuButton
            leading={
              <Icon className="w-4 h-4" path={mdiLightningBoltOutline} />
            }
            title="Doing"
            disabled={checked === TodoState.doing}
            handlerCallback={() => setChecked(TodoState.doing)}
            setOpen={setOpen}
          />
          <MenuButton
            leading={<Icon className="w-4 h-4 p-0.5" path={mdiCheck} />}
            title="Done"
            disabled={checked === TodoState.done}
            handlerCallback={() => setChecked(TodoState.done)}
            setOpen={setOpen}
          />
        </>
      )}
      contextMenu
    >
      <div
        className="relative inline-block rounded-md cursor-pointer ripple w-7 h-7"
        onClick={() =>
          setChecked((state) => {
            switch (state) {
              case TodoState.backlog:
                return TodoState.todo;
              case TodoState.todo:
                return TodoState.done;
              case TodoState.doing:
                return TodoState.done;
              case TodoState.done:
                return TodoState.todo;
            }
          })
        }
      >
        <AnimatePresence initial={false}>
          {checked === TodoState.done && (
            <motion.div
              className="absolute inset-0 p-1.5"
              key="checked"
              initial={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                pathLength: 1,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
            >
              <Icon path={mdiCheck} />
            </motion.div>
          )}
          {checked === TodoState.todo && (
            <motion.div
              className="absolute inset-0 p-0.5"
              key="unchecked"
              initial={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                pathLength: 1,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
            >
              <Icon path={mdiSquareRoundedOutline} />
            </motion.div>
          )}
          {checked === TodoState.backlog && (
            <motion.div
              className="absolute inset-0 p-0.5"
              key="backlog"
              initial={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                pathLength: 1,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
            >
              <Icon path={mdiCircleSmall} />
            </motion.div>
          )}
          {checked === TodoState.doing && (
            <motion.div
              className="absolute inset-0 p-1.5"
              key="doing"
              initial={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              exit={{
                opacity: 0,
                scale: 0.5,
                pathLength: 0,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                pathLength: 1,
              }}
              transition={{
                duration: 0.2,
                ease: "easeInOut",
              }}
            >
              <Icon path={mdiLightningBoltOutline} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Menu>
  );
}

export default memo(Checkbox);
