import { CSSProperties, memo } from "react";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { iOS } from "../../utilities";
import TreeItem from "./TreeItem";
import { Props as TreeItemProps } from "./TreeItem";
import { motion } from "framer-motion";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectHasTodoRecentlyToggled } from "../../../../../redux/slices/localSettings.slice";

const animateLayoutChanges: AnimateLayoutChanges = () => false;

function SortableTreeItem({ id, depth, ...props }: TreeItemProps) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setNodeRef,
    transform,
  } = useSortable({
    animateLayoutChanges: animateLayoutChanges,
    id: id,
    transition: null,
  });
  const style: CSSProperties = {
    transform: props.clone ? undefined : CSS.Translate.toString(transform),
  };

  const hasTodoRecentlyToggled = useAppSelector(selectHasTodoRecentlyToggled);

  const preventAnimation = isDragging === true || props.clone;

  return (
    <motion.div
      className="box-border block my-1"
      key={"motion" + id}
      animate={{
        height: "auto",
        opacity: 1,
        overflow: "visible",
      }}
      initial={
        isDragging === true
          ? false
          : props.clone
          ? {
              height: 0,
              overflow: "hidden",
              opacity: 0,
            }
          : {
              height: 0,
              overflow: "hidden",
              opacity: 0,
            }
      }
      exit={{
        height: 0,
        overflow: "hidden",
        opacity: 0,
      }}
      transition={{
        ease: "easeOut",
        delay: hasTodoRecentlyToggled ? 0.5 : 0,
        duration: preventAnimation ? 0 : 0.2,
      }}
    >
      <TreeItem
        id={id}
        key={"TreeItem-" + id}
        ref={setDraggableNodeRef}
        wrapperRef={setNodeRef}
        style={style}
        depth={depth}
        disableSelection={iOS}
        disableInteraction={isSorting}
        listeners={listeners}
        attributes={attributes}
        ghost={isDragging}
        transform={transform}
        {...props}
      />
    </motion.div>
  );
}

export default memo(SortableTreeItem);
