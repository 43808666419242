import { v4 as uuidv4 } from "uuid";

/** Basic properties for an entity */
export default interface Entity {
    /** The time at which the entity was archived. Null if the entity has not been archived. */
    archivedAt?: number

    /** The time at which the entity was created. */
    createdAt: number

    /** The unique identifier of the entity. */
    id: string

    name: string

    /** The last time at which the entity was updated. This is the same as the creation time if the entity hasn't been updated after creation. */
    updatedAt: number
}

export function newEntitiy({
    archivedAt,
    createdAt,
    id,
    name,
    updatedAt,
}: {
    archivedAt?: number,
    createdAt?: number,
    id?: string,
    name: string
    updatedAt?: number,
}) {
    return {
        archivedAt: archivedAt,
        createdAt: createdAt ?? Date.now(),
        id: id ?? uuidv4(),
        name: name,
        updatedAt: updatedAt ?? Date.now(),
    } as Entity
}