import { AnimatePresence, motion } from "framer-motion";
import CreateTodo from "../components/create_todo";
import TodoItem from "../components/houi/TodoItem";
import { useAppSelector } from "../redux/hooks";
import { selectTodosByStatus } from "../redux/selectors/todosSelectors";
import { TodoState } from "../redux/types/todo";
import PageHeader from "./components/PageHeader";

export default function DoingPage() {
  const items = useAppSelector((state) =>
    selectTodosByStatus(state, TodoState.doing)
  );

  return (
    <>
      <PageHeader title="Doing" todoCount={items.length} />
      <ul>
        <AnimatePresence>
          {items!.map((item) => (
            <motion.li
              className="block my-1"
              key={item.id}
              animate={{ height: "auto", opacity: 1 }}
              exit={{
                height: 0,
                overflow: "hidden",
                opacity: 0,
              }}
              transition={{ ease: "easeOut", delay: 0.5, duration: 0.15 }}
            >
              <TodoItem id={item.id} flat />
            </motion.li>
          ))}
        </AnimatePresence>
        <li>
          <CreateTodo
            placeholder="Create doing todo"
            status={TodoState.doing}
          />
        </li>
      </ul>
    </>
  );
}
