import { HTMLAttributes, memo, useEffect, useState } from "react";
import useMousetrap from "../../hooks/useMousetrap";
import Menu from "../loui/Menu";
import MenuButton from "../loui/Menu/MenuButton";
import Tooltip from "../loui/Tooltip";
import SelectionButton from "./SelectionButton";

export interface SelectValue {
  icon?: any;
  value: string;
  title: string;
}

interface Props
  extends Omit<HTMLAttributes<HTMLSelectElement>, "value" | "onChange"> {
  value?: string;
  values: SelectValue[];
  onChange(event?: string): void;
  toggleOpen?: void;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function Select(props: Props) {
  const [value, setValue] = useState(props.value);

  const [selected, setSelected] = useState(
    props.values.find((item) => item.value === props.value)
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.open !== undefined && props.open !== open) {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    props.onChange(value);
    setSelected(props.values.find((item) => item.value === value));
  }, [value]);

  // TODO: Shortcut "S" should open the menu.
  return (
    <>
      <Menu
        open={props.open ?? open}
        setOpen={props.setOpen ?? setOpen}
        content={(setOpenLocal: any) => (
          <>
            {props.values.map(({ icon, value, title }) => (
              <MenuButton
                key={value}
                leading={icon ? icon : undefined}
                title={title}
                handlerCallback={() => {
                  setValue(value);
                }}
                setOpen={props.setOpen ?? setOpenLocal}
                disabled={value === selected?.value}
              />
            ))}
          </>
        )}
      >
        <Tooltip description="Change status" shortcut="S" placement="bottom">
          <SelectionButton
            leading={selected?.icon ? selected?.icon : undefined}
          >
            {selected?.title}
          </SelectionButton>
        </Tooltip>
      </Menu>
    </>
  );
}

export default memo(Select);
