import MenuButton from "../../components/loui/Menu/MenuButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteAllDoneTodos } from "../../redux/middleware/todosMiddleware";
import { selectHasDoneTodos } from "../../redux/selectors/todosSelectors";
import PageHeader from "../components/PageHeader";

function DoneHeader() {
  const hasDoneTodos = useAppSelector(selectHasDoneTodos);
  const dispatch = useAppDispatch();

  const menuContent = (setOpen: any) => (
    <>
      <section className="m-4 prose w-52">
        <h1 className="">Done todos</h1>
        <p>
          Hera are all the done todos displayed. It's a view to display your
          accomplishments. Archive or delete them when you are tired of seeing
          them.
        </p>
        {/* <RaisedButton>Got it</RaisedButton> */}
      </section>
      <hr />
      <MenuButton
        title="Delete all done todos"
        handlerCallback={() => dispatch(deleteAllDoneTodos(null))}
        disabled={!hasDoneTodos}
        setOpen={setOpen}
      />
    </>
  );

  return <PageHeader title="Done" menuContent={menuContent} />;
}

export default DoneHeader;
