import { todoAdded } from "../../redux/slices/todos.slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import { useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";

import { newTodo, TodoState } from "../../redux/types/todo";
import { selectToggleCreateTodoButton } from "../../redux/slices/userSettings.slice";
import { useState } from "react";

import "./index.scss";

interface Props {
  placeholder?: string;
  status?: TodoState;
}

type FormData = {
  title: string;
};

export default function CreateTodo(props: Props) {
  const createTodoButton = useAppSelector(selectToggleCreateTodoButton);
  const dispatch = useAppDispatch();

  const [input, setInput] = useState<HTMLTextAreaElement | null>(null);
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: "onChange",
  });
  const onSubmit = (data: FormData) => {
    dispatch(
      todoAdded(
        newTodo({
          name: data.title,
          todoState: props.status,
        })
      )
    );
    reset();
  };

  const inputReg = register("title", {
    required: true,
    maxLength: 300,
  });

  // useEffect(() => {
  //   if (props.focus) {
  //     input?.focus();
  //   }
  // }, [props.focus]);

  // const handleSelect = useCallback(() => {
  //   props.setFocus?.(props.number ?? 0);
  // }, [props.number, props.setFocus]);

  return createTodoButton ? (
    <div
      ref={setWrapper}
      className="flex transition-colors rounded create-todo-wrapper hover:bg-shark-light-200 hover:dark:bg-shark-600"
    >
      <div
        className="relative flex items-center w-10 h-10 grow-0 cursor-text top-px left-px"
        onClick={(e) => input?.focus()}
      >
        <Icon
          path={mdiPlus}
          className={`inline-block mx-auto h-5 w-5 transition-colors relative ${
            watch("title") > ""
              ? errors.title
                ? "text-sunglo"
                : "textColor"
              : "create-todo-icon"
          }`}
        />
      </div>
      <form
        className="flex items-center ml-1 grow"
        onSubmit={(e) => {
          handleSubmit(onSubmit);
        }}
      >
        <TextareaAutosize
          onFocus={(e) => {
            wrapper?.classList.add("focused");
          }}
          {...{ ...inputReg, onBlur: null, ref: null }}
          ref={(ref) => {
            setInput(ref);
            inputReg.ref(ref);
          }}
          onBlur={(e) => {
            wrapper?.classList.remove("focused");
            inputReg.onBlur(e);
          }}
          // tabIndex={props.focus ? 0 : -1}
          // onClick={handleSelect}
          // onKeyPress={handleSelect}
          // onFocus={() => props.setFocus?.(props.number)}
          // onBlur={() => props.setFocus?.(undefined)}
          placeholder={props.placeholder ?? "Create todo"}
          className={`text-xs font-medium  pl-11 -ml-11 w-[calc(100%_+_2.75rem)] bg-transparent py-3 create-todo-textarea transition-colors ripple border-[1px] border-solid
          ${watch("title") > "" && errors.title ? "text-sunglo" : ""}`}
          onKeyDown={(e) => {
            if (
              e.key !== "ArrowUp" &&
              e.key !== "ArrowDown" &&
              e.key !== "Tab"
            ) {
              e.stopPropagation();
            }
            if (e.defaultPrevented) {
              return;
            }
            const key = e.key || e.keyCode;
            if (key === "Enter" || key === 13) {
              e.preventDefault();
              handleSubmit(onSubmit)();
            } else if (key === "Escape" || key === "Esc" || key === 27) {
              reset();
              (document.activeElement as HTMLElement).blur();
            }
          }}
        />
      </form>
    </div>
  ) : (
    <></>
  );
}
