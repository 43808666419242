import Icon from "@mdi/react";
import { forwardRef } from "react";
import { FlatButton } from "../../ui";
import "./index.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  color?: string;
  name: string;
  active?: boolean;
  marginRightNone?: boolean;
  className?: string;
}

const SidenavButton = forwardRef<HTMLButtonElement, Props>(
  (
    { icon, color, name, active, marginRightNone, className, ...props },
    ref
  ) => {
    return (
      <FlatButton ref={ref} className={`w-full ${className}`} {...props}>
        <div className="flex items-center justify-start ">
          {
            icon ? (
              <Icon
                path={icon}
                className={`grow-0 inline-block w-4 h-4 -ml-0.5 ${
                  color ? color : "text-current"
                }`}
              />
            ) : (
              <></>
            )
            // <div
            //   className={`grow-0 inline-block w-2 h-2 m-1 my-auto rounded-sm ${
            //     color
            //       ? color
            //       : "bg-cadet-blue-600 dark:bg-cadet-blue outline-cadet-blue-600 dark:outline-cadet-blue"
            //   } ${active ? "outline outline-offset-1 " : ""}`}
            // />
          }
          <span
            className={`grow text-xs my-auto font-medium  ${
              !icon ? "" : "ml-2"
            }`}
          >
            {name}
          </span>
        </div>
      </FlatButton>
    );
  }
);

export default SidenavButton;
