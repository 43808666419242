import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "reflect-metadata";
import AppLayout from "./components/app_layout";
import AuthHandler from "./components/AuthHandler";
import "./index.scss";
import BacklogPage from "./pages/BacklogPage";
import Continue from "./pages/Continue";
import DoingPage from "./pages/DoingPage";
import DonePage from "./pages/Done";
import TodoPage from "./pages/TodosPage";
import { store } from "./redux/store";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={"/app"}>
      <AuthHandler>
        <Routes>
          <Route path="/continue" element={<Continue />}></Route>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<TodoPage />}></Route>
            <Route path="/doing" element={<DoingPage />}></Route>
            <Route path="/todo" element={<TodoPage />}></Route>
            <Route path="/backlog" element={<BacklogPage />}></Route>
            <Route path="/done" element={<DonePage />}></Route>
          </Route>
        </Routes>
      </AuthHandler>
    </BrowserRouter>
  </Provider>
);
