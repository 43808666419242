import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectTodo } from "../../../redux/selectors/todosSelectors";
import { todoAdded, todoUpdated } from "../../../redux/slices/todos.slice";
import {
  getTodoState,
  newTodo,
  TodoState,
  todoStateChanges,
} from "../../../redux/types/todo";
import Tooltip from "../../loui/Tooltip";
import { RaisedButton } from "../../ui";

import { diff } from "deep-object-diff";
import { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import SelectStatus from "./selectStatus";

type FormData = {
  name: string;
};

function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  return keys.reduce((o, k) => ((o[k] = obj[k]), o), {} as Pick<T, K>);
}

const editableProperties = [
  "name",
  "completedAt",
  "startedAt",
  "activeAt",
] as const;

// interface EditableTodoProps
//   extends Partial<
//     Pick<Todo, "name" | "completedAt" | "startedAt" | "activeAt">
//   > {}

export default function CreateTodoModal({
  id,
  onClose,
}: {
  id?: string;
  onClose?: () => void;
}) {
  const todo = useAppSelector((state) => selectTodo(state, id ?? ""));
  const dispatch = useAppDispatch();

  const [todoState, setTodoState] = useState<TodoState | undefined>();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() === "enter") {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, { passive: true });

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: "onChange",
  });

  const onSubmit = (data: FormData) => {
    dispatch(
      todo
        ? todoUpdated({
            id: todo.id,
            changes: diff(pick(todo, ...editableProperties), {
              name: data.name,
              ...todoStateChanges(todoState ?? TodoState.backlog, todo),
            }),
          })
        : todoAdded(newTodo({ name: data.name, todoState: todoState }))
    );
    reset();
    if (onClose) onClose();
  };

  // useEffect(() => {
  //   nameInput.ref((input: HTMLTextAreaElement) => {
  //     console.log("value is " + input.value);
  //   });

  //   nameInput.ref((input: HTMLTextAreaElement) => {
  //     input.setSelectionRange(todo?.name?.length ?? 0, todo?.name?.length ?? 0);
  //   });
  // }, []);

  return (
    <div className=" w-[600px]">
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit);
        }}
      >
        <div className="flex">
          <TextareaAutosize
            autoFocus
            {...register("name", {
              value: todo?.name,
              required: true,
              maxLength: 300,
            })}
            placeholder="Todo Name"
            className={`text-lg font-medium w-[calc(100%_+_2.75rem)] bg-transparent placeholder-shark-light-500 p-2 border-0 dark:placeholder-shark-400 m-2 ${
              watch("name") > "" && errors.name ? "text-sunglo" : ""
            }`}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.defaultPrevented) {
                return;
              }
              const key = e.key || e.keyCode;
              if (key === "Enter" || key === 13) {
                e.preventDefault();
                handleSubmit(onSubmit)();
              } else if (key === "Escape" || key === "Esc" || key === 27) {
                (document.activeElement as HTMLElement).blur();
              }
            }}
          />
        </div>
        <div className="flex border-t border-shark-light-200 dark:border-shark-dark-600">
          <div className="m-3 grow">
            <SelectStatus
              value={
                todo
                  ? getTodoState(todo).toString()
                  : TodoState.backlog.toString()
              }
              onChange={(event) => setTodoState(Number(event))}
            />
          </div>
          <div className="m-3 grow-0">
            <Tooltip
              description={todo ? "Save todo" : "Create new todo"}
              shortcut="⏎"
              placement="bottom"
            >
              <RaisedButton
                type="submit"
                disabled={errors.name !== undefined}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                }}
              >
                {todo ? "Save todo" : "Create todo"}
              </RaisedButton>
            </Tooltip>
          </div>
        </div>
      </form>
    </div>
  );
}
