import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, authObserver } from "../firebase";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { selectIsLoading } from "../redux/slices/localSettings.slice";

import { ReactComponent as CreateTodoIcon } from "../pages/create-todo-icon.svg";
import { fetchUser } from "../redux/middleware/firebase/firebaseUserMiddleware";

interface LocationType {
  pathname: string;
  search: string;
  hash: string;
  state: { from: string };
  key: string;
}

function AuthHandler({ children }: any) {
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>();

  const isLoading = useAppSelector(selectIsLoading);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation() as LocationType;

  // const updateUser = (user: any) => {
  //   if (user) {
  //     dispatch(
  //       setUser({
  //         id: user.uid,
  //         name: user.displayName,
  //         email: user.email,
  //       })
  //     );
  //   } else {
  //     dispatch(removeUser(null));
  //   }
  // };

  // const sendUser = (user: any) => {
  //   if (user && location.pathname === "/continue") {
  //     navigate("/", { replace: true });
  //   } else if (!user) {
  //     navigate("/continue", { replace: true });
  //   }
  // };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsSignedIn(!!user);
      if (user) {
        const _user = {
          id: user.uid,
          active: true,
          email: user.email,
          name: user.displayName,
          displayName: user.displayName,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          createdAt: new Date().valueOf(),
          updatedAt: new Date().valueOf(),
        };

        dispatch(fetchUser(_user));

        if (location.pathname === "/continue") {
          navigate("/", { replace: true });
        }
      } else {
        navigate("/continue", { replace: true });
      }
    });

    return authObserver;
  }, []);

  return isSignedIn !== false ? (
    isLoading ? (
      <LoadingIndicator />
    ) : (
      children
    )
  ) : (
    children
  );
}

function LoadingIndicator() {
  return (
    <div className="absolute inset-0 flex items-center loading-logo">
      <div className="flex w-20 h-20 p-4 mx-auto mb-8 textColor">
        <CreateTodoIcon />
      </div>
    </div>
  );
}

export default AuthHandler;
