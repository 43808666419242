import {
  useSensors,
  useSensor,
  MouseSensor,
  TouchSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  MeasuringStrategy,
  DragOverlay,
  DragStartEvent,
  DragMoveEvent,
  DragOverEvent,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { AnimatePresence } from "framer-motion";
import { useState, useMemo, useRef, useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import {
  toggleCollapsed,
  updateSortorder,
} from "../../../redux/middleware/todosMiddleware";
import SortableTreeItem from "./components/TreeItem/SortableTreeItem";
import { sortableTreeKeyboardCoordinates } from "./keyboardCoordinates";
import { TreeItem, SensorContext, FlattenedItem } from "./types";
import {
  flattenTree,
  removeChildrenOf,
  getProjection,
  getChildCount,
} from "./utilities";

interface Props {
  items: TreeItem[];
  collapsible?: boolean;
  indentationWidth?: number;
}

export function SortableTree({
  items,
  collapsible,
  indentationWidth = 40,
}: Props) {
  const dispatch = useAppDispatch();
  const setItems = (state: TreeItem[]) => {
    dispatch(updateSortorder(state));
  };

  const [activeId, setActiveId] = useState<string | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);

  /** Flattens children while dragging? */
  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);

    const collapsedItems = flattenedTree?.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        collapsed && children?.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, items]);

  /** Gives information about where the tree item is projected. */
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, false, indentationWidth)
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 25,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 100,
        tolerance: 25,
      },
    }),
    useSensor(PointerSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 100,
        tolerance: 25,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems]
  );

  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId.toString());
    setOverId(activeId.toString());
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id?.toString() ?? null);
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const clonedItems: FlattenedItem[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      // const newItems = buildTree(sortedItems);

      setItems(sortedItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty("cursor", "");
  }

  // function handleRemove(id: UniqueIdentifier) {
  //   setItems((items) => removeItem(items, id));
  // }

  function handleCollapse(id: string) {
    dispatch(toggleCollapsed(id));
  }

  return (
    <DndContext
      sensors={sensors}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <AnimatePresence initial={false}>
          {flattenedItems.map(({ id, children, collapsed, depth }) => (
            <SortableTreeItem
              className="my-1"
              key={"SortableTreeItem-" + id}
              id={id}
              depth={id === activeId && projected ? projected.depth : depth}
              indentationWidth={indentationWidth}
              collapsed={Boolean(collapsed && children?.length)}
              onCollapse={
                collapsible && children?.length
                  ? () => handleCollapse(id)
                  : undefined
              }
            />
          ))}
        </AnimatePresence>
        <DragOverlay
          dropAnimation={null}
          transition={undefined}
          modifiers={undefined}
          className="drag-overlay"
          style={{
            transition: undefined,
          }}
        >
          {activeId && activeItem && (
            <SortableTreeItem
              key={"active - " + activeId}
              id={activeId}
              depth={activeItem.depth}
              clone
              childCount={getChildCount(items, activeId) + 1}
              indentationWidth={indentationWidth}
            />
          )}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
}
