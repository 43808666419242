import { mdiChevronRight } from "@mdi/js";
import Icon from "@mdi/react";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "../../../redux/hooks";
import { selectTodoName } from "../../../redux/selectors/todosSelectors";

function ParentText({ id }: { id: string }) {
  const todoName = useAppSelector((state) => selectTodoName(state, id));

  return (
    <AnimatePresence>
      {todoName && (
        <motion.span
          className="block font-medium text-xxs disabledTextColor "
          animate={{ height: "auto", opacity: 1 }}
          exit={{
            height: 0,
            overflow: "hidden",
            opacity: 0,
          }}
          transition={{ ease: "easeOut", delay: 0.5, duration: 0.15 }}
        >
          {todoName}
          <Icon
            path={mdiChevronRight}
            className="relative inline-block w-3 h-3 -top-px"
          />
        </motion.span>
      )}
    </AnimatePresence>
  );
}

export default ParentText;
