function Membership() {
  return (
    <section className="grow">
      <h1 className="p-6 text-lg font-normal">Membership</h1>

      <hr className="big" />

      <section className="p-6 prose">
        <h1>Free for now</h1>
        <p>
          Right now the app is free because it's still in its early development
          stages, features can be removed and new ones can appear.
        </p>
        <p>
          The app wont be free forever though, and when we decide to charge for
          the app there is no garantee that there will be any free tier. This is
          because software development costs lots of money and so to have a
          healthy sustainable app we need to monetize it.
        </p>
        <p>
          Hopefully you will find value in the app and think it's valuable
          enough to pay for, but if you are fine with any todo app just to write
          down your todos (or even on a piece of paper) there are lots of free
          apps available better suited for you.
        </p>
      </section>
    </section>
  );
}

export default Membership;
