import {
  Action,
  combineReducers,
  configureStore,
  Dispatch,
  MiddlewareAPI,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  localSettingsReducer,
  userSettingsReducer,
  todosReducer,
  undoRedoReducer,
  userReducer,
} from "./slices";

import { firebaseTodosMiddleware } from "./middleware/firebase/firebaseTodosMiddleware";
import { todosMiddleware } from "./middleware/todosMiddleware";
import { undoRedoMiddleware } from "./middleware/undoRedoMiddleware";
import { firebaseUserSettingsMiddleware } from "./middleware/firebase/firebaseUserSettingsMiddleware";
import { userSettingsMiddleware } from "./middleware/userSettingsMiddleware";
import { userMiddleware } from "./middleware/userMiddleware";
import { firebaseUserMiddleware } from "./middleware/firebase/firebaseUserMiddleware";

const rootReducer = combineReducers({
  user: userReducer,
  localSettings: localSettingsReducer,
  userSettings: userSettingsReducer,
  todos: todosReducer,
  undoRedo: undoRedoReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      (state) => userMiddleware(state),
      firebaseUserMiddleware,
      (state) => userSettingsMiddleware(state),
      firebaseUserSettingsMiddleware,
      (state) => todosMiddleware(state),
      firebaseTodosMiddleware,
      undoRedoMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type middleWareFunction = (
  api: MiddlewareAPI<Dispatch<PayloadAction<any>>, RootState>,
  next: Dispatch<PayloadAction<any>>,
  action: PayloadAction<any>
) => void;
