import CreateTodo from "../components/create_todo";
import { SortableTree } from "../components/houi/Sortable/SortableTree";
import MenuButton from "../components/loui/Menu/MenuButton";
import Submenu from "../components/loui/Menu/Submenu";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  deleteAllActiveTodos,
  deleteAllDoneTodos,
  deleteAllTodos,
} from "../redux/middleware/todosMiddleware";
import {
  selectAllNodeTodosNested,
  selectHasActiveTodos,
  selectHasDoneTodos,
  selectIsEmptyState,
} from "../redux/selectors/todosSelectors";
import {
  selectPageSettings,
  setPageSettings,
} from "../redux/slices/userSettings.slice";
import { PageSettings, TodosSettings } from "../redux/types/userSettings";
import { TodoState } from "../redux/types/todo";
import PageHeader from "./components/PageHeader";

export default function TodoPage() {
  const showDoneTodos: boolean | undefined = useAppSelector((state) =>
    selectPageSettings(state, "todos")
  )?.settings.showDoneTodos;
  const showDoingTodos: boolean | undefined = useAppSelector((state) =>
    selectPageSettings(state, "todos")
  )?.settings.showDoingTodos;
  const items = useAppSelector((state) =>
    selectAllNodeTodosNested(
      state,
      showDoneTodos === true,
      showDoingTodos === true
    )
  );

  return (
    <>
      <Header todoCount={items.length} />

      <SortableTree items={items} collapsible />

      <CreateTodo status={TodoState.todo} />
    </>
  );
}

function Header({ todoCount, ...props }) {
  const hasDoneTodos = useAppSelector(selectHasDoneTodos);
  const hasActiveTodos = useAppSelector(selectHasActiveTodos);
  const hasAnyTodos = !useAppSelector(selectIsEmptyState);
  const pageSettings: PageSettings<TodosSettings> | undefined = useAppSelector(
    (state) => selectPageSettings(state, "todos")
  );
  const dispatch = useAppDispatch();

  const menuContent = (setOpen: any) => (
    <>
      <section className="m-4 prose w-52">
        <h1>Todo</h1>
        <p>
          Here are all the todos displayed. Sort it however you want by drag and
          drop.
        </p>
        {/* <RaisedButton>Got it</RaisedButton> */}
      </section>
      <hr />
      {/* <MenuButton title="Show sub-todos" toggle /> */}
      <MenuButton
        title="Show doing todos"
        toggle={pageSettings?.settings.showDoingTodos === true}
        handlerCallback={() =>
          dispatch(
            setPageSettings({
              ...pageSettings,
              settings: {
                ...pageSettings.settings,
                ...{
                  showDoingTodos: !pageSettings?.settings.showDoingTodos,
                },
              },
            })
          )
        }
      />
      <MenuButton
        title="Show done todos"
        toggle={pageSettings?.settings.showDoneTodos === true}
        handlerCallback={() =>
          dispatch(
            setPageSettings({
              ...pageSettings,
              settings: {
                ...pageSettings.settings,
                ...{
                  showDoneTodos: !pageSettings?.settings.showDoneTodos,
                },
              },
            })
          )
        }
      />
      <hr />
      {/* <MenuButton title="Archive done todos" />
      <hr /> */}
      <Submenu
        content={(setOpen: any) => (
          <>
            <MenuButton
              title="Delete all done todos"
              handlerCallback={() => dispatch(deleteAllDoneTodos(null))}
              disabled={!hasDoneTodos}
              setOpen={setOpen}
            />
            <MenuButton
              title="Delete all active todos"
              handlerCallback={() => dispatch(deleteAllActiveTodos(null))}
              disabled={!hasActiveTodos}
              setOpen={setOpen}
            />
            <MenuButton
              title="Delete all todos"
              handlerCallback={() => dispatch(deleteAllTodos(null))}
              disabled={!hasAnyTodos}
              setOpen={setOpen}
            />
          </>
        )}
      >
        <MenuButton title="Delete" hasSubMenu />
      </Submenu>
    </>
  );

  return (
    <PageHeader
      title="Todo"
      todoCount={todoCount}
      menuContent={menuContent}
      // rightContent={
      //   <>
      //     <RaisedButton className="border-r-0 rounded-r-none" mini disabled>
      //       <div className="w-3 h-34">
      //         <Icon path={mdiViewSequential} />
      //       </div>
      //     </RaisedButton>
      //     <RaisedButton className="border-l-0 rounded-l-none" mini>
      //       <div className="w-3 h-34">
      //         <Icon path={mdiViewWeek} />
      //       </div>
      //     </RaisedButton>
      //   </>
      // }
    />
  );
}
