import { mdiMenuRight } from "@mdi/js";
import Icon from "@mdi/react";
import SimpleToggle from "../SimpleToggle";

function MenuButton(props: {
  leading?: any;
  title: string;
  disabled?: boolean;
  shortcuts?: string;
  handlerCallback?: Function;
  toggle?: boolean;

  /** If true, adds an arrow indicator to indicate a sub menu. */
  hasSubMenu?: boolean;

  /** Clicking the menu doesn't close it, so you have to pass setOpen() for it to close on clicking. */
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <button
      className={`relative flex px-4 py-1.5 overflow-hidden y-px w-full text-left rounded-none  textColor
      ${props.disabled ? "" : "ripple-menu"}
      `}
      onClick={
        !props.disabled
          ? (e) => {
              e.stopPropagation();
              props.handlerCallback?.call?.(e);
              props.setOpen?.(false);
            }
          : undefined
      }
      disabled={props.disabled}
    >
      <div
        className={`flex grow ${
          props.disabled ? "opacity-50 dark:opacity-25" : ""
        }`}
      >
        {props.leading && (
          <div className={`grow-0 -ml-1.5 mr-2 my-auto text-sm`}>
            {props.leading}
          </div>
        )}
        <div className={`grow mr-4`}>
          <span>{props.title}</span>
        </div>
        {props.shortcuts !== undefined && (
          <div className="flex items-center h-2 m-auto grow-0">
            <span className="shortcuts">{props.shortcuts}</span>
          </div>
        )}
        {props.toggle !== undefined && (
          <div className="flex items-center w-6 h-2 my-auto ml-1 grow-0">
            <SimpleToggle value={props.toggle} />
          </div>
        )}
        {props.hasSubMenu === true && (
          <div className="relative flex items-center w-5 h-5 -mb-1 -mr-1 -top-0.5 -right-1 grow-0 disabledTextColor">
            <Icon path={mdiMenuRight} />
          </div>
        )}
      </div>
    </button>
  );
}

export default MenuButton;
