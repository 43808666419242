import {
  FlattenedItem,
  TreeItem,
  TreeItems,
} from "../../components/houi/Sortable/types";
import { findItemDeep } from "../../components/houi/Sortable/utilities";
import { todosSelectors } from "../slices/todos.slice";
import { RootState } from "../store";
import { getTodoState, TodoState } from "../types/todo";

export const selectAllNodeTodosFlat = (state: RootState) =>
  todosSelectors.selectAll(state).map<FlattenedItem>(
    (todo) =>
    ({
      id: todo.id,
      parentId: todo.parentId,
      collapsed: todo.collapsed,
    } as FlattenedItem)
  );

export const selectNodeTodosFlatByStatus = (state: RootState, status: TodoState | TodoState[]) =>
  todosSelectors
    .selectAll(state)
    .filter((item) => Array.isArray(status) ? status.indexOf(getTodoState(item)) !== -1 : getTodoState(item) === status)
    .map<FlattenedItem>(
      (todo) =>
      ({
        id: todo.id,
        parentId: todo.parentId,
        collapsed: todo.collapsed,
      } as FlattenedItem)
    );

export const selectAllActiveTodos = (state: RootState) =>
  todosSelectors
    .selectAll(state)
    .filter((todo) => !todo.completedAt)
    .map((todo) => ({ id: todo.id } as TreeItem));

export const selectAllDoneTodos = (state: RootState) =>
  todosSelectors
    .selectAll(state)
    .filter((todo) => todo.completedAt)
    .sort((a, b) => b.completedAt - a.completedAt)
    .map((todo) => ({ id: todo.id } as TreeItem));

export const selectTodosByStatus = (state: RootState, status: TodoState) =>
  todosSelectors
    .selectAll(state)
    .filter((todo) => getTodoState(todo) === status)
    .map((todo) => ({ id: todo.id } as TreeItem));

export const selectAllNodeTodosNested = (
  state: RootState,
  showDoneTodos?: boolean,
  showDoingTodos?: boolean,
) => {
  const itemStatesToSelect = [TodoState.todo];
  if (showDoneTodos === true) {
    itemStatesToSelect.push(TodoState.done)
  }
  if (showDoingTodos === true) {
    itemStatesToSelect.push(TodoState.doing)
  }

  const _flat: FlattenedItem[] = selectNodeTodosFlatByStatus(state, itemStatesToSelect);
  const _root: TreeItem[] = [];

  _flat.forEach((node) => {
    if (!node.parentId) return _root.push(node);

    const parentIndex = _flat.findIndex((el) => el.id === node.parentId);

    if (parentIndex === -1) return;

    if (!_flat[parentIndex].children) {
      return (_flat[parentIndex].children = [node]);
    }
    _flat[parentIndex]?.children?.push(node);
  });

  return _root;
};

// export const selectNestedNodeTodos = (state: Todo[], id: string) => {
//     const _flat: TreeItem[] = state as unknown as TreeItem[];
//     const _root: TreeItem[] = []; let _start = false; _flat.forEach((node) => {
//         if (node.id === id) _start = true;
//         if (_start === true) {
//             if (!node.parentId) {
//                 _start = false;
//                 return _root.push(node);
//             }
//             const parentIndex = _flat.findIndex((el) => el.id === node.parentId);

//             if (parentIndex === -1) return _root.push(node);

//             if (!_flat[parentIndex].children) {
//                 return (_flat[parentIndex].children = [node]);
//             } _flat[parentIndex]?.children?.push(node);
//         }
//     }); return _root;
// }

export const selectFlatNodeTodos = (state: TreeItem[], id?: string) => {
  const _flat: FlattenedItem[] = state as unknown as FlattenedItem[];
  let _root: TreeItem[] = [];
  let _doLoop = false;
  let _firstParent = true;

  _flat.forEach((node) => {
    if (node.id === id) _doLoop = true;

    if (_doLoop || id === undefined) {
      if (_firstParent && id !== undefined) {
        _firstParent = false;
        return _root.push(node);
      }

      const parentIndex = _flat.findIndex((el) => el.id === node.parentId);

      if (parentIndex === -1) {
        _doLoop = false;

        if (id === undefined) {
          _root.push({ ...node, ...{ depth: 0 } });

          if (node.children) {
            _root.push(...flattenOneLevel(node.children as FlattenedItem[], 1));
          }
        }
        return;
      }

      if (!_flat[parentIndex].children) {
        return (_flat[parentIndex].children = [node]);
      }

      _flat[parentIndex].children!.push(node);
    }
  });

  let depth: number = 1;

  let flatRoot: FlattenedItem[] = _root as FlattenedItem[];

  while (
    flatRoot.find((el) => el.children !== undefined && el.children.length > 0)
  ) {
    flatRoot = flattenOneLevel(flatRoot, depth);
    depth++;
  }

  return flatRoot;
};

const flattenOneLevel = (state: FlattenedItem[], depth: number) => {
  state.forEach((node) => {
    const nodeIndex = state.findIndex((el) => el.id === node.id);
    if (node.children) {
      node.children.forEach((el) => {
        state.push({ ...el, depth: depth });
      });
      state[nodeIndex].children = [];
    }
  });
  return state;
};

// More sofistication
function idsOfChildren(items: TreeItem[], ids: string[] = []): string[] {
  return items.reduce((acc, { children }, index) => {
    if (children !== undefined && children?.length) {
      return [
        items[index].id,
        ...idsOfChildren(children, [
          ...acc,
          ...children.map((child) => child.id),
        ]),
      ];
    }
    return [...acc];
  }, ids);
}

export function getChildrenIdsOfIds(items: TreeItems, ids: string[]) {
  let result: string[] = [];
  for (const index in ids) {
    const item =
      findItemDeep(items, ids[index]) ??
      items.find((item) => item.id === ids[index]);
    if (item !== undefined) {
      result = [
        ...result,
        item!.id,
        ...(item?.children ? idsOfChildren(item.children) : []),
      ];
    }
  }
  return result;
}

// End of sofistication

export const selectAllTodoIds = (state: RootState) =>
  todosSelectors.selectIds(state);

export const selectTodos = (state: RootState, ids?: string[]) =>
  ids
    ? todosSelectors
      .selectAll(state)
      .filter((todo) => ids.indexOf(todo.id) > -1)
    : todosSelectors.selectAll(state);

export const selectTodo = (state: RootState, id: string) =>
  todosSelectors.selectAll(state).find((todo) => todo.id === id);

export const selectTodoName = (state: RootState, id: string) =>
  todosSelectors.selectAll(state).find((todo) => todo.id === id)?.name;

export const selectIsEmptyState = (state: RootState) =>
  todosSelectors.selectTotal(state) <= 0;

export const selectHasActiveTodos = (state: RootState) =>
  todosSelectors.selectAll(state).filter((todo) => !todo.completedAt).length >
  0;

export const selectHasDoneTodos = (state: RootState) =>
  todosSelectors.selectAll(state).filter((todo) => todo.completedAt).length > 0;
