import {
  mdiCircleSmall,
  mdiSquareRoundedOutline,
  mdiLightningBoltOutline,
  mdiCheck,
} from "@mdi/js";
import Icon from "@mdi/react";
import { HTMLAttributes, memo, useState } from "react";
import useMousetrap from "../../../hooks/useMousetrap";
import { TodoState } from "../../../redux/types/todo";
import Select, { SelectValue } from "../../ui/Select";

interface Props
  extends Omit<HTMLAttributes<HTMLSelectElement>, "value" | "onChange"> {
  value?: string;
  onChange(event?: string): void;
}

const values: SelectValue[] = [
  {
    icon: <Icon className="w-4 h-4" path={mdiCircleSmall} />,
    value: TodoState.backlog.toString(),
    title: "Backlog",
  },
  {
    icon: <Icon className="w-4 h-4" path={mdiSquareRoundedOutline} />,
    value: TodoState.todo.toString(),
    title: "Todo",
  },
  {
    icon: <Icon className="w-4 h-4" path={mdiLightningBoltOutline} />,
    value: TodoState.doing.toString(),
    title: "Doing",
  },
  {
    icon: <Icon className="w-4 h-4 p-0.5" path={mdiCheck} />,
    value: TodoState.done.toString(),
    title: "Done",
  },
];

function SelectStatus(props: Props) {
  const [open, setOpen] = useState(false);

  useMousetrap("s", () => {
    setOpen((s) => !s);
  });

  return (
    <Select
      open={open}
      setOpen={setOpen}
      value={props.value}
      values={values}
      onChange={props.onChange}
    />
  );
}

export default memo(SelectStatus);
