import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectUser } from "../../../../../redux/slices/user.slice";
import { RaisedButton } from "../../../../ui";
import { deleteUser } from "../../../../../redux/middleware/userMiddleware";

function General() {
  const user = useAppSelector(selectUser);
  const [name, setName] = useState(user.name);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setName(user.name);
  }, [user.name]);

  return (
    <section className="grow">
      <h1 className="p-6 text-lg font-normal">Profile</h1>

      <hr className="big" />

      <section className="p-6">
        <h2 className="text-xs font-semibold">Full name</h2>
        <input
          type="text"
          className="text-input"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <br />
        <br />
        <h2 className="text-xs font-semibold">Username</h2>
        <input
          type="text"
          className="text-input"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <br />
        <br />
        <h2 className="text-xs font-semibold my-1.5 disabledTextColor">
          Email
        </h2>
        <p className="disabledTextColor">{user.email}</p>
        <br />
        <br />
        <RaisedButton>Save</RaisedButton>
        <br />
        <br />
        <br />
        <hr />
        <br />
        <br />
        <h2 className="text-xs font-semibold">
          Delete your Create Todo account permanentely
        </h2>
        <RaisedButton onClick={(e) => dispatch(deleteUser())} className="mt-2">
          Delete account
        </RaisedButton>
      </section>
    </section>
  );
}

export default General;
