import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { ButtonHTMLAttributes, forwardRef } from "react";
import FlatButton from "./FlatButton";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  leading?: any;
}

const FlatButtonWithArrow = forwardRef<HTMLButtonElement, Props>(
  ({ type = "button", className, children, ...props }, ref) => {
    return (
      <FlatButton
        type={type}
        className={"inline-flex items-center " + className}
        ref={ref}
        {...props}
      >
        {props.leading && (
          <div className={`grow-0 ml-0.5 mr-2 my-auto text-sm`}>
            {props.leading}
          </div>
        )}

        <div className={`grow`}>{children}</div>

        <div className={`grow-0`}>
          <Icon
            path={mdiChevronDown}
            className="inline-block w-4 h-4 mb-px ml-1 disabledTextColor grow-0"
          />
        </div>
      </FlatButton>
    );
  }
);

export default FlatButtonWithArrow;
