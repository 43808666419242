import { NavLink } from "react-router-dom";
import { FlatButton } from "../../ui";

interface Props {
  to?: string;
  title: string;
}

function SidenavTitle(props: Props) {
  return (
    <NavWrapper to={props.to}>
      <h2
        className={`flex items-center justify-start font-semibold tracking-widest uppercase text-xxs disabledTextColor h-7 ${
          props.to ? "" : "px-2"
        } `}
      >
        {props.title}
      </h2>
    </NavWrapper>
  );
}

function NavWrapper({ to, children }: { to?: string; children: any }) {
  if (to) {
    return (
      <NavLink to={to} tabIndex={-1}>
        <FlatButton className="relative w-full">{children}</FlatButton>
      </NavLink>
    );
  }
  return children;
}

export default SidenavTitle;
