import Icon from "@mdi/react";
import { forwardRef } from "react";
import MCButton from "../../loui/MCButton";

interface SidenavIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
  color?: string;
  stopPropagation?: boolean;
  highlightOnHover?: boolean;
  disableTextColor?: boolean;
}

const SidenavIconButton = forwardRef<HTMLButtonElement, SidenavIconButtonProps>(
  (
    {
      icon,
      color,
      className,
      stopPropagation,
      highlightOnHover,
      disableTextColor,
      ...props
    },
    ref
  ) => {
    return (
      <MCButton
        ref={ref}
        className={`p-0 m-0 flex items-center justify-center h-7 w-7 transition-colors ${className} ${
          highlightOnHover
            ? "disabledTextColor textColorHover textColorFocus"
            : ""
        }`}
        wrapperClassname="inline-block"
        stopPropagation={stopPropagation}
        disableTextColor={disableTextColor}
        {...props}
      >
        <div className="flex items-center">
          <Icon
            path={icon}
            className={`w-4 h-4  ${color ? color : "text-current "}`}
          />
        </div>
      </MCButton>
    );
  }
);

export default SidenavIconButton;
