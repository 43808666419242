import { Placement } from "@popperjs/core";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { usePopper } from "react-popper";

interface Props {
  placement?: Placement;

  /** The content that should be displayed in the menu. */
  content: any;

  /** If true, sets max-width to 13rem */
  maxWidth?: boolean;

  /** Sets the display property of the div wrapper. Defaults to block. */
  wrapperDisplay?: "inline-block" | "block";

  children: any;
}

function Submenu({
  placement = "right-start",
  content,
  maxWidth,
  wrapperDisplay = "block",
  children,
}: Props) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [show, setShow] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-5, 0],
        },
      },
    ],
  });

  return (
    <div
      ref={setReferenceElement}
      className={`${wrapperDisplay} ${show ? "open" : "closed"}`}
      onMouseEnter={() => {
        setShow(true);
      }}
      onMouseLeave={() => {
        setShow(false);
      }}
      onFocus={() => setShow(true)}
      onBlur={() => setShow(false)}
    >
      {children}
      <AnimatePresence>
        {show && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-50"
          >
            <motion.div
              initial={{
                opacity: 1,
                scale: 1,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0.9,
              }}
              transition={{
                ease: "easeInOut",
                duration: 0.15,
              }}
              className={`py-1 inline-block text-xs menu ${
                maxWidth ? "max-w-[13rem]" : ""
              }`}
            >
              {content(setShow)}
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Submenu;
